import React, { useState, useEffect, useRef } from "react";
import { ChromePicker } from "react-color";

export default function ColorPicker(props) {
  const pickerRef = useRef();
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState(props.defaultValue);

  useEffect(() => {
    setColor(props.defaultValue);
  }, [props.defaultValue]);

  useEffect(() => {
    props.handleThemeColor(color);
  }, [props, color]);

  const handleHidePicker = (e) => {
    if (pickerRef.current) {
      if (e && pickerRef && pickerRef.current?.contains(e.target)) return;
      document.removeEventListener("click", handleHidePicker);
      setShowPicker(false);
    }
  };

  const handleShowPicker = (e) => {
    if (!showPicker) {
      e.stopPropagation();
      setShowPicker(true);
      document.addEventListener("click", handleHidePicker);
    }
  };

  const handleColorUpdate = (color) => {
    setColor(color.hex);
  };

  const handleNewColor = (e) => {
    setColor(e.target.value);
  };

  return (
    <div className="input-group">
      <label>{props.label}</label>

      <div className="input-color-picker">
        <span className="input-color-swatch" style={{ backgroundColor: color }} onClick={handleShowPicker}></span>

        <input type="text" value={color} onChange={handleNewColor} />

        {showPicker && (
          <div ref={pickerRef} className="color-picker">
            <ChromePicker color={color} onChange={handleColorUpdate} />
          </div>
        )}
      </div>
    </div>
  );
}
