import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEditMascot } from "../hooks/useEditMascot";
import { useTasks } from "../hooks/useTasks";
import Modal from "./Modal";
import Popover from "./Popover";
import { useMascot } from "../hooks/useMascot";
import { useCurrentEditors } from "../hooks/useCurrentEditors";

export default function PublishBar() {
  const history = useHistory();
  const { mascotId } = useParams();
  const { mascot } = useMascot();
  const { lastSavedDate, publishDraft, hasToBePublished, unpublish } = useEditMascot();
  const [showSavedLabel, setShowSavedLabel] = useState();
  const [showModalPublish, setShowModalPublish] = useState();
  const [showModalUnpublish, setShowModalUnpublish] = useState();
  const [showUnpublishPopover, setShowUnpublishPopover] = useState(false);
  const { hasPendingTasks, embeddingTask } = useTasks();
  const { currentEditors } = useCurrentEditors();
  const [publishLoading, setPublishLoading] = useState(false);

  useEffect(() => {
    if (lastSavedDate) {
      setShowSavedLabel(true);
      setTimeout(() => {
        setShowSavedLabel(false);
      }, 2000);
    }
  }, [lastSavedDate]);

  const goBack = () => {
    history.push(`/mascot/${mascotId}/chat`);
  };

  const publish = async () => {
    setPublishLoading(true);
    await publishDraft();
    setShowModalPublish(false);
    setPublishLoading(false);
    goBack();
  };

  return (
    <>
      <div className="publish-bar">
        <button className="xsmall dark outline" onClick={goBack}>
          Back
        </button>

        <div className="editors">
          {Object.values(currentEditors)
            .sort((a, b) => {
              const e1 = a.firstName.charAt(0) + a.lastName.charAt(0);
              const e2 = b.firstName.charAt(0) + b.lastName.charAt(0);
              if (e1 < e2) return -1;
              if (e1 > e2) return 1;
              return 0;
            })
            .map((editor, i) => (
              <div
                className="editor"
                key={i}
                style={{ backgroundColor: "#" + editor.color }}
                title={editor.firstName + " " + editor.lastName}
              >
                <span>{editor.firstName.charAt(0) + editor.lastName.charAt(0)}</span>
              </div>
            ))}
        </div>
        <span className="editing-label meta small">
          {Object.keys(currentEditors).length === 1 && <>You are editing</>}
          {Object.keys(currentEditors).length > 1 && <>{Object.keys(currentEditors).length} Current Editors</>}
        </span>

        {showSavedLabel && <div className="saved-label meta">Changes Saved!</div>}

        <div className="publish-options">
          {hasToBePublished() && (
            <div className="publish-warning meta small white">
              <i className="icon-info"></i> Your changes need to be published
            </div>
          )}

          <div className="segment">
            <button
              disabled={!hasToBePublished() || hasPendingTasks}
              className="xsmall action"
              onClick={() => setShowModalPublish(true)}
            >
              Publish
            </button>

            <button className="xsmall action" disabled={!mascot.published || embeddingTask} onClick={() => setShowUnpublishPopover(true)}>
              <i className="icon-chevron-down"></i>
            </button>
          </div>

          {showUnpublishPopover && (
            <Popover close={() => setShowUnpublishPopover(false)} className="right">
              <div
                className="popover-item danger"
                onClick={() => {
                  setShowUnpublishPopover(true);
                  setShowModalUnpublish(true);
                }}
              >
                <i className="icon-warn"></i> Unpublish
              </div>
            </Popover>
          )}
        </div>
      </div>

      <Modal
        title="Publish Changes"
        size="small"
        isOpen={showModalPublish}
        close={() => setShowModalPublish(false)}
        hideX
        action={
          <>
            <button disabled={publishLoading} onClick={publish} className="action">
              Publish Mascot
            </button>
            <button disabled={publishLoading} onClick={() => setShowModalPublish(false)}>Cancel</button>
          </>
        }
      >
        {mascot && mascot.data && mascot.data.stale && !embeddingTask ? (
          <>
            <p>It may take some time for the mascot to update with the latest data in the training index.</p>
            <p>You will receive a confirmation email when the mascot is updated.</p>
            <p>Other changes will become live immediately.</p>
          </>
        ) : (
          <>
            <p>Your changes will become live immediately.</p>
          </>
        )}
      </Modal>

      <Modal
        title="Unpublish Mascot"
        size="small"
        isOpen={showModalUnpublish}
        close={() => setShowModalUnpublish(false)}
        action={
          <>
            <button
              onClick={() => {
                setShowModalUnpublish(false);
                unpublish();
                goBack();
              }}
              className="danger"
            >
              Unpublish
            </button>
            <button onClick={() => setShowModalUnpublish(false)}>Cancel</button>
          </>
        }
      >
        <p>This will make the mascot unavailable to all users.</p>
      </Modal>
    </>
  );
}
