import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useFormik } from "formik";
import { client } from "../services/api";
import { useAuth } from "../hooks/useAuth";

export default function Account() {
  const { currentUser, updateCurrentUser, currentOrganization, updateOrganization } = useAuth();
  const [owner, setOwner] = useState({});
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (currentOrganization.admins) {
      setOwner(currentOrganization.admins.find((a) => a._id === currentOrganization.owner._id));
    }
  }, [currentOrganization]);

  const { handleChange, values, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      user: currentUser,
      org: currentOrganization,
    },
  });

  const handleSave = async () => {
    if (values.user !== currentUser) {
      updateCurrentUser(values.user);
    }
    if (values.org !== currentOrganization) {
      client.updateOrganization(values.org);
      updateOrganization(values.org);
      setFieldValue("org", currentOrganization);
    }
  };

  useEffect(() => {
    setTouched(values.user !== currentUser || values.org !== currentOrganization);
  }, [values, currentUser, currentOrganization]);

  return (
    <div className="main-wrapper">
      <Header></Header>

      <div className="content-wrapper narrow">
        <div className="page-title">
          <h1>Manage My Account</h1>

          <button className="action" disabled={!touched} onClick={() => handleSave()}>
            Save Changes
          </button>
        </div>

        <div className="input-group">
          <label className="light">First Name</label>
          <input type="text" className="outline" name="user.firstName" value={values.user.firstName} onChange={handleChange} />
        </div>

        <div className="input-group">
          <label className="light">Last Name</label>
          <input type="text" className="outline" name="user.lastName" value={values.user.lastName} onChange={handleChange} />
        </div>

        <div className="input-group">
          <label className="light">Email address</label>
          <input type="text" className="outline" name="user.email" disabled defaultValue={values.user.email} />
        </div>

        {(currentUser._id === owner._id || currentUser.superadmin) && (
          <div className="input-group">
            <label className="light">My Organization</label>
            <input
              type="text"
              className="outline"
              name="org.name"
              disabled={
                (currentUser._id !== owner._id && !currentUser.superadmin) ||
                (currentOrganization.disabled && !currentUser.superadmin)
              }
              defaultValue={values.org.name || ""}
              onChange={handleChange}
            />
          </div>
        )}
      </div>
    </div>
  );
}
