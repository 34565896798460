import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import moment from "moment";
import { client } from "../services/api";
import { useHistory } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export default function ModalSessionsByDay({ isShowModalDailySessions, setShowModalDailySessions }) {
  const [mascots, setMascots] = useState([]);
  const history = useHistory();
  const { currentOrganization } = useAuth();

  useEffect(() => {
    if (!!isShowModalDailySessions) {
      client.getStatsMascotPerDay(currentOrganization._id, isShowModalDailySessions).then((data) => {
        if (data.status === 200) {
          setMascots(data.data);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      isOpen={!!isShowModalDailySessions}
      close={() => setShowModalDailySessions(false)}
      size="small"
      className="modal-insight"
    >
      <div className="meta">{moment(isShowModalDailySessions).format("MMMM Do, YYYY")}</div>
      <h2 className="modal-title">{mascots.reduce((acc, m) => (acc += m.sessions), 0)} Sessions</h2>

      <div className="list">
        <ul>
          {mascots.map((m, i) => (
            <li
              key={i}
              className="list-item clickable"
              onClick={() => {
                history.push(`/mascot/${m._id}/insights/overview`);
              }}
            >
              <div className="list-item-name"><span>{m.name}</span></div>
              <div className="meta">{m.sessions}</div>
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  );
}
