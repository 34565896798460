import React from "react";
import TreeView from "react-accessible-treeview";
import { FaSquare, FaCheckSquare, FaMinusSquare } from "react-icons/fa";
import Loader from "./Loader";

function TreeViewFilePicker({ data, loadData, onSelect, renderItem, selectedIds, disableCheck, defaultExpandedIds }) {
  const maxSelect = 15;
  return (
    <>
      <div className="treeview-filepicker">
        <TreeView
          defaultExpandedIds={defaultExpandedIds}
          data={data}
          onLoadData={loadData}
          onSelect={onSelect}
          selectedIds={selectedIds}
          multiSelect
          togglableSelect
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            isSelected,
            isHalfSelected,
            getNodeProps,
            level,
            handleSelect,
            handleExpand,
          }) => {
            const branchNode = (isExpanded, element) => {
              return isExpanded && element.children.length === 0 && !element.filtered ? (
                <Loader classNames="small" />
              ) : !isExpanded || element.filtered ? (
                <i className="icon-chevron-right"></i>
              ) : (
                <i className="icon-chevron-down"></i>
              );
            };

            return element.parent !== null && (
              <div {...getNodeProps({ onClick: handleExpand })}>
                {isBranch && branchNode(isExpanded, element)}

                {element.selectable !== false && (!disableCheck || element.isWebPage || element.selectable) && (
                  <CheckBoxIcon
                    className={"checkbox-icon" + (!isSelected && selectedIds.length >= maxSelect ? " disabled" : "")}
                    disabled={true}
                    onClick={(e) => {
                      if (!(!isSelected && selectedIds.length >= maxSelect)) {
                        handleSelect(e);
                      }
                      e.stopPropagation();
                    }}
                    variant={isHalfSelected ? "some" : isSelected ? "all" : "none"}
                  />
                )}

                {renderItem(element)}

                {element.isWebPage && <div className="hover"></div>}
              </div>
            );
          }}
        />
      </div>
    </>
  );
}

const CheckBoxIcon = ({ variant, ...rest }) => {
  switch (variant) {
    case "all":
      return <FaCheckSquare {...rest} />;
    case "none":
      return <FaSquare {...rest} />;
    case "some":
      return <FaMinusSquare {...rest} />;
    default:
      return null;
  }
};

export default TreeViewFilePicker;
