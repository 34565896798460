import React from "react";
import { NavLink } from "react-router-dom";

import BgImg from "../images/sign-in-bg.png";
import Logo from "../images/logo-wiseox.svg";

export function MissingOrg() {
  return (
    <div className="auth-wrapper" style={{ backgroundImage: `url(${BgImg})` }}>
      <div className="logo-wrapper">
        <img className="logo" src={Logo} alt="WiseOx Logo" />
      </div>

      <div className="signup-form">
        <h1>Whoops!</h1>
        <p>Looks like this account is not associated with any organization.</p>

        <NavLink className="button positive large" to={`/logout`}>
          Go to Sign In
        </NavLink>
      </div>
    </div>
  );
}
