import React, { useState, useEffect, useCallback } from "react";
import Modal from "./Modal";
import Loader from "./Loader";
import { useAuth } from "../hooks/useAuth";
import { servicesClient } from "../services/servicesApi";
import TreeViewFilePicker from "./TreeViewFilePicker";
// import classNames from "classnames";
import { FileIcon } from "../components/FileIcon";

const initialData = [
  {
    name: "",
    id: 0,
    children: [],
    parent: null,
  }
];

function ConfluenceFilePicker({ isShown, setShown, onSelect }) {
  const { currentOrganization } = useAuth();
  const [data, setData] = useState(initialData);
  const [filteredData, setFilteredData] = useState(initialData);
  const [selectedIds, setSelectedIds] = useState([]);
  const [exportedSelectedIds, setExportedSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [filterTab, setFilterTab] = useState(1);

  useEffect(() => {
      setLoading(true);
      currentOrganization.confluenceConnected && servicesClient.getConfluenceNode(currentOrganization._id, 0).then(result => {
        setLoading(false);
        result.ok && setData((value) => updateTreeData(value, 0, result.data));
      });
  }, [currentOrganization]);

  const updateTreeData = (list, id, children) => {
    const existingIds = list.map(node => node.id);
    const filteredChildren = children.filter(el => !existingIds.includes(el.id));
    filteredChildren.forEach(node => {
      if (typeof node.id === 'string' && node.id.startsWith('s')) {
        node.selectable = false;
      }
    });
    const data = list.map(node => ({ ...node })).map((node) => {
      if (node.id === id) {
        node.children = children.map(child => ({ ...child })).map((el) => el.id);
        if (filteredChildren.length === 0 && node.empty) {
          node.isBranch = false;
        }
      }
      return node;
    });
    return data.concat(filteredChildren);
  };

  const loadData = useCallback(async ({ element }) => {
    const result = await servicesClient.getConfluenceNode(currentOrganization._id, element.id);
    if (result.ok) {
      result.data.length === 0 && (element.empty = true);
      setData((value) => updateTreeData(value, element.id, result.data));
    }
  }, [currentOrganization]);

  const selectAllIds = () => {
    setSelectedIds(data.map(i => i.id));
  };

  const _onSelect = useCallback(({ treeState }) => {
    setExportedSelectedIds(data.filter(d => treeState.selectedIds.has(d.id)).map(d => ({ id: d.id, name: d.name })));
  }, [data]);

  const filterTree = useCallback((value, filerByType = true) => {
    if (value.length === 0) {
      setFilteredData(data);
      return;
    }
    const filtered = [];
    data.forEach((item) => {
      if (item.id === 0) {
        return;
      }
      let check = false;
      if (filerByType && typeof item.id === 'string') {
        check = item.id.startsWith(value)
      } else {
        check = item.name.toLowerCase().includes(value.toLowerCase());
      }
      if (check) {
        if (!filtered.find((x) => x.id === item.id)) {
          filtered.push(item);
        }
      }
    });
    filtered.unshift(
      Object.assign({
        ...data[0],
        children: data[0].children.filter((id) =>
          filtered.find((fitem) => fitem.id === id)
        ),
      })
    );
    const existingIds = filtered.map(node => node.id);
    filtered.forEach(item => {
      if (item.id !== 0 && !existingIds.includes(item.parent)) {
        item.parent = 0;
      }
      if (item.children && Array.isArray(item.children)) {
        item.filtered = true;
        item.children = item.children.filter(childId => existingIds.includes(childId));
      }
    });
    setFilteredData(updateTreeData(initialData, 0, filtered));
  }, [data]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <Modal
      title="Confluence Content"
      isOpen={!!isShown}
      close={() => setShown(false)}
      size="large"
      className="picker-modal"
      action={
        <>
          <button className="action" onClick={() => { onSelect(exportedSelectedIds); setShown(false); }}>
            Select Content
          </button>
          <button onClick={() => setShown(false)}>
            Cancel
          </button>
        </>
      }
    >
      <input
        type="text"
        className="small picker-search"
        placeholder="Search Confluence"
        onChange={(event) => filterTree(event.target.value, false)}
      />

      {/* Aug 19th - Filters are buggy, hiding for now */}

      {/* <div className="filter-bar">
        <button
          className={classNames('xxsmall', filterTab !== 1 && 'outline')}
          onClick={() => {setFilterTab(1); filterTree('')}}
        >
          All
        </button>
        <button
          className={classNames('xxsmall', filterTab !== 2 && 'outline')}
          onClick={() => {setFilterTab(2); filterTree('s')}}
        >
          Spaces
        </button>
        <button
          className={classNames('xxsmall', filterTab !== 3 && 'outline')}
          onClick={() => {setFilterTab(3); filterTree('b')}}
        >
          Blogs
        </button>
        <button
          className={classNames('xxsmall', filterTab !== 4 && 'outline')}
          onClick={() => {setFilterTab(4); filterTree('p')}}
        >
          Pages
        </button>
        <button
          className={classNames('xxsmall', filterTab !== 5 && 'outline')}
          onClick={() => {setFilterTab(5); filterTree('a')}}
        >
          Files
        </button>
      </div> */}

      <div className="select-all">
        <span className="meta" onClick={() => selectAllIds()}>Select All</span>
        <span className="meta" onClick={() => setSelectedIds([])}>Deselect All</span>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <TreeViewFilePicker 
          data={filteredData} 
          disableCheck={false}
          loadData={loadData} 
          selectedIds={selectedIds} 
          onSelect={_onSelect} 
          renderItem={(element) => <span className="name"><FileIcon doc={element} type='confluence' /> {element.name}</span>}
        />
      )}
    </Modal>
  );
}

export default ConfluenceFilePicker;