import classNames from "classnames";

// Libs
import moment from "moment";
import { toast } from "react-toastify";

// Context
import { useAuth } from "../hooks/useAuth";

// Component
import MascotImage from "./MascotImage";
import Popover from "./Popover";
import MDEditor from "@uiw/react-md-editor";
import { useEffect, useRef } from "react";
import { useMessages } from "../hooks/useMessages";
import { client } from "../services/api";

export default function MessageChat({
  message,
  mascot,
  trainingMode,
  togglePopup,
  i,
  showedPopup,
  addRank,
  showModalFeedback,
  setShowExcerpts,
  selectedMessage,
  chatWrapperEl,
}) {
  const classPopover = ".message.assistant .message-content";
  const messageEl = useRef();
  const { isAuthenticated, clientId } = useAuth();
  const messages = useMessages();

  useEffect(() => {
    const initClickListenerElement = (el) => {
      el?.addEventListener("mousedown", (e) => {
        if (e.target.closest(classPopover)) e.target.closest(classPopover).classList.add("not-hoverable");
      });
      el?.addEventListener("mouseup", openMenu);
    };

    const openMenu = (e) => {
      let node = e.target;
      if (!node) return;
      if (node.closest(classPopover)) node.closest(classPopover).classList.add("not-hoverable");

      const selection = window.getSelection();
      if (selection && selection.toString()) return;

      if (document.body.createTextRange) {
        const range = document.body.createTextRange();
        range.moveToElementText(node);
        range.select();
      } else if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(node);
        selection.removeAllRanges();
        selection.addRange(range);
      } else {
        console.warn("Could not select text in node: Unsupported browser.");
      }
    };

    if (messages && messageEl.current && message.role === "assistant") {
      attachLinkStats(messageEl.current);
      initClickListenerElement(messageEl.current);
    }
    // eslint-disable-next-line
  }, [messageEl, message.role]);

  // Stats
  const attachLinkStats = (el) => {
    let selector = ".wmde-markdown a";

    const countLinkClicked = () => {
      let conversationId = messages.conversation._id;
      messages.setInitSessionStats((initSessionStats) => {
        if (conversationId !== "new") {
          if (!initSessionStats[conversationId]) {
            client.createSessionStats(conversationId, clientId).then(async (res) => {
              if (res.ok) {
                initSessionStats[conversationId] = res.data?._id;
                await client.updateSessionStatsCountLinksClicked(res.data._id);
                messages.setInitSessionStats({ ...initSessionStats });
              }
            });
          } else {
            client.updateSessionStatsCountLinksClicked(initSessionStats[conversationId]);
          }
        }
        return initSessionStats;
      });
    };

    el.querySelectorAll(selector).forEach((e) => {
      e.removeEventListener("click", countLinkClicked);
      e.addEventListener("click", countLinkClicked);
    });
  };

  useEffect(() => {
    if (selectedMessage && message._id === selectedMessage) {
      setTimeout(() => {
        chatWrapperEl.current?.scrollTo({
          top: messageEl.current?.offsetTop,
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }, 400);
    }
    // eslint-disable-next-line
  }, [messageEl.current]);

  const copyToClipboard = async (message) => {
    try {
      await navigator.clipboard.writeText(message.text);
      toast.success("Content copied to clipboard");
    } catch (err) {
      console.error("Failed to copyToClipboard: ", err);
    }
  };

  return (
    <div
      ref={messageEl}
      className={classNames(
        "message",
        message.role,
        message.role === "user" && messages && messages.newMessageAnimation && "new-message-animation"
      )}
      style={message.role === "user" ? { borderColor: mascot.theme && mascot.theme.brandColor } : null}
    >
      <div className="message-header">
        {message.role === "assistant" && <MascotImage mascot={mascot}></MascotImage>}

        {message.role === "user" && (
          <div className="user-avatar" style={{ backgroundColor: mascot.theme && mascot.theme.brandColor }}>
            <h3>{message.username?.charAt(0) || "G"}</h3>
          </div>
        )}

        <div className="message-header-title">
          <h5>{message.role === "assistant" ? mascot.name : message.username || "Guest"}</h5>
          <span className="meta small">{new moment(message.timestamp).format("MMM Do, hh:mma")}</span>
        </div>

        {message.role === "assistant" && (
          <>
            {isAuthenticated && mascot.ownMascot && trainingMode ? (
              <div className={classNames("message-actions rank-response", message.feedback)}>
                <button className="rank-button xsmall outline" onClick={(e) => togglePopup(i, message)}>
                  {!message.feedback && "Rank"}
                  {message.feedback === "positive" && <i className="icon-thumbs-up"></i>}
                  {message.feedback === "negative" && <i className="icon-thumbs-down"></i>}
                </button>

                {showedPopup[i] && (
                  <Popover close={(e) => togglePopup(i, message)} className="animate-left">
                    <p>
                      <b>Rank This Response</b>
                    </p>

                    <button className="outline pill wide positive" onClick={(e) => addRank(i, message, "positive")}>
                      <i className="icon-thumbs-up"></i>
                      <span>Looks Good</span>
                    </button>

                    <button className="outline pill wide negative" onClick={(e) => showModalFeedback(i, message, "negative")}>
                      <i className="icon-thumbs-down"></i>
                      <span>Not Good</span>
                    </button>
                  </Popover>
                )}
              </div>
            ) : (
              <>
                <div className="message-actions">
                  <div className="icon-btn">
                    <i className="icon-copy" onClick={() => copyToClipboard(message)}></i>
                  </div>

                  {isAuthenticated && mascot.ownMascot && (
                    <div className="icon-btn">
                      <i className="icon-file-info" onClick={() => setShowExcerpts(message)}></i>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>

      <div className={classNames("message-content", !mascot.prompt_settings?.inline_actions && "not-hoverable")}>
        {message.role === "assistant" ? (
          <MDEditor.Markdown
            linkTarget="_blank"
            source={message.text}
            data-color-mode="light"
            style={{
              color: mascot.theme && mascot.theme.accentColor,
              backgroundColor: "transparent",
            }}
          />
        ) : (
          <p style={{ color: mascot.theme && mascot.theme.accentColor }}>{message.text}</p>
        )}
      </div>
    </div>
  );
}
