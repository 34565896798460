import React, { useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { client } from "../services/api";
import { useEffect } from "react";

export function Invitation() {
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  let { isAuthenticated, currentUser, logOut } = useAuth();

  useEffect(() => {
    client.getInvite(query.get("token")).then((result) => {
      if (!result.ok) {
        logOut();
        history.push(`/login?invitationToken=${query.get("token")}`);
      } else if (currentUser && result.data.email !== currentUser.email) {
        logOut();
        history.push(`/login?invitationToken=${query.get("token")}&email=${encodeURIComponent(result.data.email)}`);
      } else if (isAuthenticated && currentUser) {
        client.acceptInvite(query.get("token"), currentUser._id).then((_) => history.push(`/`));
      } else {
        client.checkUserByEmail(result.data.email).then((checkUser) => {
          if (checkUser.ok) {
            history.push(`/login?invitationToken=${query.get("token")}&email=${encodeURIComponent(result.data.email)}`);
          } else {
            history.push(`/signup?invitationToken=${query.get("token")}`);
          }
        });
      }
    });
  }, [history, query, isAuthenticated, currentUser, logOut]);

  return <div className="main-wrapper"></div>;
}
