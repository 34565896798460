import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export function Logout() {
  const { logOut } = useAuth();
  const history = useHistory();

  useEffect(() => {
    logOut();
    history.push("/");
  }, [history, logOut]);

  return <div>Loading</div>;
}
