import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";

// Components
import MascotSidebarInsights from "../components/MascotSidebarInsights";
import HeaderInsights from "../components/HeaderInsights";

// Pages
import MascotInsightsSessions from "./MascotInsightsSessions";
import MascotInsightsInteraction from "./MascotInsightsInteractions";
import MascotInsightsOverview from "./MascotInsightsOverview";

export default function MascotInsightsLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [intervalTime, setIntervalTime] = useState(7);

  return (
    <div className="mascot-wrapper">
      <MascotSidebarInsights editing sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}></MascotSidebarInsights>

      <div className="mascot-insights-wrapper">
        <HeaderInsights intervalTime={intervalTime} onSetIntervalTime={setIntervalTime}></HeaderInsights>

        <Switch>
          <Route path="/mascot/:mascotId/insights/overview">
            <MascotInsightsOverview intervalTime={intervalTime} />
          </Route>

          <Route path="/mascot/:mascotId/insights/sessions">
            <MascotInsightsSessions intervalTime={intervalTime} />
          </Route>

          <Route path="/mascot/:mascotId/insights/interactions">
            <MascotInsightsInteraction intervalTime={intervalTime} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
