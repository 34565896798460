import React, { useState, useCallback, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import useQuery from '../hooks/useQuery';
import { client } from "../services/api";

import BgImg from "../images/sign-in-bg.png";
import Logo from "../images/logo-wiseox.svg";

export function Connect() {
  const query = useQuery();
  const { loading } = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [orgs, setOrgs] = useState();
  const [userId, setUserId] = useState();
  const [error, setError] = useState(false);
  const [values, setValues] = useState({ username: "", password: "" });
  const usernameInputRef = useRef();
  const passwordInputRef = useRef();
  const orgsSelectRef = useRef();

  function handleChange() {
    setValues({ username: usernameInputRef?.current?.value, password: passwordInputRef?.current?.value });
  }

  const handleSelectOrg = useCallback(async () => {
    const url = new URL(query.get("next"));
    url.searchParams.set("token", userId);
    url.searchParams.set("orgId", orgsSelectRef?.current?.value);
    window.location.href = url;
  }, [userId, query]);

  const handleSubmission = useCallback(async () => {
    setError(false);
    setSubmitting(true);
    const loginResult = await client.logIn(values);
    if (loginResult.ok) {
      setUserId(loginResult.data.user._id);
      client.api.setHeaders({ Authorization: `Bearer ${loginResult.data.user.token}` });
      const orgsResult = await client.getUserOrganizations(loginResult.data.user._id);
      orgsResult.ok && setOrgs(orgsResult.data);
    } else {
      setError("Invalid credentials!");
    }
    setSubmitting(false);
  }, [values]);

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmission();
    }
  };

  return (
    <div className="auth-wrapper" style={{ backgroundImage: `url(${BgImg})` }}>
      <div className="logo-wrapper">
        <img className="logo" src={Logo} alt="WiseOx Logo" />
      </div>

      <div className="signup-form">
        {query.get("success") === "true" ? (
          <>
            <h1>Connected!</h1>
            <p>Your Slack account is now connected to WiseOx.</p>
            <a className="button action large" href={query.get("next")}>
              Go to Slack
            </a>
          </>
        ) : orgs ? (
          <>
            <h1>Select Organization</h1>
            <p>Please select an organization to connect to Slack.</p>

            <div className="select">
              <select ref={orgsSelectRef}>
                {orgs &&
                  orgs.map((o) => (
                    <option key={o._id} value={o._id}>
                      {o.name}
                    </option>
                  ))}
              </select>
            </div>

            <button className="action large" disabled={loading || submitting} onClick={handleSelectOrg} tabIndex="3">
              Finish
            </button>
          </>
        ) : (
          <>
            <h1>Connect to Slack</h1>

            <div className="input-group">
              <input
                type="email"
                name="username"
                placeholder="Email address"
                value={values.username}
                onChange={handleChange}
                tabIndex="1"
                ref={usernameInputRef}
              />
            </div>

            <div className="input-group">
              <input
                type="password"
                required
                name="password"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
                tabIndex="2"
                onKeyDown={_handleKeyDown}
                ref={passwordInputRef}
              />
            </div>

            {error && <div className="error">{error}</div>}

            <p className="small">
              By connecting to Slack, you agree to the WiseOx{" "}
              <a href="https://wiseox.com/legal/terms-of-service" target="_blank" rel="noreferrer">
                Terms of Service
              </a>
            </p>

            <button className="action large" disabled={loading || submitting} onClick={handleSubmission} tabIndex="3">
              Connect
            </button>

            <NavLink className="forgot-link" to="/forgot-password">
              Forgot Password?
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
}
