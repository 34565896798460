import { React } from "react";
import { useLocation } from "react-router-dom";

import BgImg from "../images/sign-in-bg.png";
import Logo from "../images/logo-wiseox.svg";

export function SignupConfirmed() {
  const location = useLocation();
  let email = location?.state?.email;

  return (
    <div className="auth-wrapper" style={{ backgroundImage: `url(${BgImg})` }}>
      <div className="logo-wrapper">
        <img className="logo" src={Logo} alt="WiseOx Logo" />
      </div>

      <div className="signup-form">
        <h1>Verify Your Account</h1>
        <p>Please check the email we've sent to {email} to verify your account.</p>
      </div>
    </div>
  );
}
