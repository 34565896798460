import { useState } from "react";

export default function Debugger(props) {
  // Debug vars
  const debugVars = {};

  // State
  const [side, setSide] = useState("right");
  const [show, setShow] = useState(true);
  const [min, setMin] = useState(false);

  // Utils
  const swicthSide = () => {
    if (side === "left") {
      setSide("right");
    } else {
      setSide("left");
    }
  };

  const printJson = (value) => {
    return <pre>{JSON.stringify(value, null, 2)}</pre>;
  };

  // Render
  return (
    show &&
    (min ? (
      <div className={"debugger-wrapper-min " + side} onClick={() => setMin(false)}>
        D
      </div>
    ) : (
      <div className={"debugger-wrapper " + side}>
        <div className="debugger-btn-bar">
          <button className="debugger-btn" onClick={swicthSide}>
            Switch side
          </button>
          <button className="debugger-btn" onClick={() => setMin(true)}>
            _
          </button>
          <button className="debugger-btn" onClick={() => setShow(false)}>
            X
          </button>
        </div>
        <h3>Debugger</h3>

        {/* DEBUGGER CONTENT */}
        <div className="debugger-content">
          {Object.keys(debugVars).map((name, index) => (
            <div key={index}>
              <label>{name}</label>
              {printJson(debugVars[name])}
            </div>
          ))}
        </div>
      </div>
    ))
  );
}
