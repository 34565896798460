import React, { useState, useContext, createContext, useEffect, useCallback, useRef } from "react";
import { useAuth } from "./useAuth.js";
import { useParams } from "react-router-dom";
import { client } from "../services/api.js";
import { useConversations } from "./useConversations.js";
import { useSocket } from "./ws.js";
import moment from "moment";
import { useDebouncedCallback } from "use-debounce";
import { servicesClient } from "../services/servicesApi.js";

const messagesContext = createContext();

function useProvideMessages() {
  const { mascotId } = useParams();
  // Context
  const { mascot, selectedConversation, setNewConversation, updateConversationLastPrompt } = useConversations();
  const { currentUser, clientId } = useAuth();
  const { socket } = useSocket();

  // Conversation
  const [loadingConversation, setLoadingConversation] = useState(true);
  const [conversation, setConversation] = useState({});

  // Messages
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Running answer
  const [streamingMessage, setStreamingMessage] = useState({});
  const [runningAnswer, setRunningAnswer] = useState(null);
  const [pendingAnswer, setPendingAnswer] = useState({});

  // Stats
  const [initSessionStats, setInitSessionStats] = useState({});

  // Refs
  const chatEndEl = useRef();
  const chatWrapperEl = useRef();

  // Animations
  const [newMessageAnimation, setNewMessageAnimation] = useState(false);

  // Functions
  const getPreviousMessages = useCallback(
    async (conversationId) => {
      setLoadingConversation(true);
      let messages = [];
      if (!mascot._id) return;

      if (conversationId && conversationId !== "new") {
        let messagesData = await client.getMessages(conversationId);
        if (messagesData.ok) {
          messages = messagesData.data;
        }
      } else if (mascot.prompt_settings && mascot.prompt_settings.reverse_prompt) {
        messages.push({
          role: "assistant",
          text: mascot.prompt_settings.reverse_prompt_text,
          conversation: conversationId,
          user: currentUser ? currentUser._id : null,
          source_documents: [],
          timestamp: new Date(),
        });
      }

      if (pendingAnswer[conversationId]) {
        messages.push({
          role: "user",
          text: pendingAnswer[conversationId],
          conversation: conversationId,
          user: currentUser ? currentUser._id : null,
          username: currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : "Guest",
          source_documents: [],
          timestamp: new Date(),
        });
      }

      setMessages(messages);
      setLoadingConversation(false);
    },
    // eslint-disable-next-line
    [currentUser, mascot]
  );

  // Websockets messaging
  useEffect(() => {
    if (!socket) return;
    function messageSent(data) {
      setRunningAnswer(data["answerId"]);
      if (data["conversation"] !== conversation._id) {
        return;
      }
    }

    function onCompletion(data) {
      // Ignore messages for other mascot
      if (data.mascotId !== mascot._id) {
        return;
      }

      if ("error" in data) {
        let convId = data.conversation || data.conversationId;
        const partial_answer = streamingMessage[convId];
        pendingAnswer[convId] = "";
        setPendingAnswer(pendingAnswer);
        streamingMessage[convId] = "";
        setStreamingMessage(streamingMessage);
        setLoading(false);
        setRunningAnswer(null);
        if (data.error !== "Stopped") {
          setMessages((messages) => [
            ...messages,
            {
              role: "system",
              text: `Error: ${data.value}`,
            },
          ]);
        } else {
          setMessages((messages) => [
            ...messages,
            {
              role: "assistant",
              text: partial_answer,
            },
          ]);
        }
      } else if ("next_word" in data) {
        if (!streamingMessage[data.conversation]) streamingMessage[data.conversation] = "";
        streamingMessage[data.conversation] += data.next_word;
        updateStreamingMessage(streamingMessage);
      } else {
        streamingMessage[data.conversation] = "";
        setRunningAnswer(null);
        setStreamingMessage(streamingMessage);
        pendingAnswer[data.conversation] = "";
        setPendingAnswer(pendingAnswer);
        let newResponse = data.message;
        newResponse._id = data.messageId;
        newResponse.messageIndex = data.messageIndex;

        if (selectedConversation?._id === data.conversation) {
          setMessages((messages) => [...messages, newResponse]);
        }

        setLoading(false);

        chatWrapperEl.current?.scrollTo({
          top: chatEndEl.current?.offsetTop,
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });

        // Send message to parent Iframe
        if (window.parent) {
          window.parent.postMessage(
            {
              type: "received-message",
              message: newResponse.text,
              mascotId: mascot._id,
              conversation: data.conversation,
            },
            "*"
          );
        }

        // Check if links shown
        // eslint-disable-next-line
        const regexMdLinks = /(http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/gm;
        let matches = newResponse.text?.match(regexMdLinks);
        // Get unique
        if (matches) {
          matches = matches.filter((value, index, array) => {
            return array.indexOf(value) === index;
          });
        }

        for (let url in matches) {
          if (initSessionStats[data.conversation]) {
            client.updateSessionStatsCountLinksShown(initSessionStats[data.conversation], url);
          } else {
            console.warn("Session stats not found");
          }
        }
      }
    }

    socket.on("completion", onCompletion);
    socket.on("messageSent", messageSent);
    return () => {
      socket.off("completion", onCompletion);
      socket.off("messageSent", messageSent);
    };
    // eslint-disable-next-line
  }, [socket, mascot, conversation, messages, selectedConversation, initSessionStats]);

  const updateStreamingMessage = useDebouncedCallback(async (streamingMessage) => {
    setStreamingMessage({ ...streamingMessage });
  }, 1);

  const handleStopStream = useCallback(async () => {
    socket.emit("stop", { answer_id: runningAnswer });
    setRunningAnswer(null);
    streamingMessage[selectedConversation._id] = "";
    setStreamingMessage({ ...streamingMessage });
    setLoading(false);
  }, [socket, runningAnswer, selectedConversation, streamingMessage]);

  const handleSubmitWs = useCallback(
    async (topicPrompt, isTopicClick) => {
      if (!socket) return;
      topicPrompt = topicPrompt ? topicPrompt : newMessage;
      if (!topicPrompt) return;

      // Set prop class for animation
      setNewMessageAnimation(true);
      // Remove prop class after animation has finished
      setTimeout(() => {
        setNewMessageAnimation(false);
      }, 300);

      window.parent.postMessage(
        {
          type: "sending-message",
          message: topicPrompt,
          mascotId: mascot._id,
          conversation: conversation._id,
        },
        "*"
      );

      setLoading(true);

      let newPrompt = [
        ...messages,
        {
          role: "user",
          text: topicPrompt,
          user: currentUser ? currentUser._id : null,
          username: currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : "Guest",
          clientId,
        },
      ];
      setMessages((messages) => newPrompt);
      setNewMessage("");

      setTimeout(() => {
        chatWrapperEl.current?.scrollTo({
          top: chatEndEl.current?.offsetTop,
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }, 100);

      if (conversation._id === "new") {
        delete conversation._id;
        conversation.user = currentUser ? currentUser._id : null;
        conversation.clientId = clientId;
        conversation.mascot = mascotId;
        conversation.data = [];
        conversation.created = new Date();
        conversation.title = conversation.title || new moment(conversation.created).format("MM-DD-YYYY hh:mm");
        conversation.lastPrompt = new Date().toISOString();
        const res = await client.createConversation(conversation);
        if (!res.ok) {
          throw res.data;
        }
        conversation._id = res.data._id;
        pendingAnswer[conversation._id] = topicPrompt;
        setPendingAnswer(pendingAnswer);

        if (!conversation.title || conversation.title === "New Conversation") {
          const resTitle = await servicesClient.initConversationTitle(mascotId, conversation._id, topicPrompt);

          if (resTitle.ok) {
            conversation.title = resTitle.data.title;
          }
        }
        setNewConversation({ ...conversation });
      } else {
        pendingAnswer[conversation._id] = topicPrompt;
        setPendingAnswer(pendingAnswer);
        updateConversationLastPrompt(conversation);
      }

      // Save stats session
      let overTimeLimit =
        messages.length > 0 ? new Date(new Date() - 60 * 60 * 1000) > new Date(messages[messages.length - 1].timestamp) : false;
      if (conversation._id !== "new" && (!initSessionStats[conversation._id] || overTimeLimit)) {
        let newSessionStats = await client.createSessionStats(conversation._id, clientId);
        if (newSessionStats.ok) {
          initSessionStats[conversation._id] = newSessionStats.data?._id;
          setInitSessionStats(initSessionStats);
          if (isTopicClick) {
            client.updateSessionStatsCountTopic(newSessionStats.data?._id);
          }
        }
      } else {
        if (initSessionStats[conversation._id] !== true) {
          client.updateSessionStats(initSessionStats[conversation._id]);
          if (isTopicClick) {
            client.updateSessionStatsCountTopic(initSessionStats[conversation._id]);
          }
        }
      }

      // Send socket message
      const data = {
        mascotId,
        conversation: conversation._id,
        clientId,
        user: currentUser ? currentUser._id : null,
        text: topicPrompt,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        sessionStats: initSessionStats[conversation._id],
      };

      socket.emit("sendMessage", data);
    },
    [
      socket,
      conversation,
      messages,
      mascotId,
      currentUser,
      newMessage,
      clientId,
      initSessionStats,
      pendingAnswer,
      mascot,
      setNewConversation,
      updateConversationLastPrompt,
    ]
  );

  return {
    getPreviousMessages,
    handleStopStream,
    handleSubmitWs,
    streamingMessage,
    runningAnswer,
    loadingConversation,
    conversation,
    setConversation,
    messages,
    setMessages,
    chatEndEl,
    chatWrapperEl,
    newMessage,
    setNewMessage,
    loading,
    newMessageAnimation,
    setInitSessionStats,
  };
}

export function ProvideMessages({ children }) {
  const messages = useProvideMessages();
  return <messagesContext.Provider value={messages}>{children}</messagesContext.Provider>;
}

export const useMessages = () => {
  return useContext(messagesContext);
};
