import React from "react";
import Header from "../components/Header";
import classNames from "classnames";
import { client } from "../services/api";
import { useAuth } from "../hooks/useAuth";

import IconSlack from "../images/icons/slack.svg";
import IconGoogleDrive from "../images/icons/google-drive.svg";
import IconConfluence from "../images/icons/confluence.svg";
import IconOneDrive from "../images/icons/one-drive.svg";
import IconSharepoint from "../images/icons/sharepoint.svg";
import IconZendesk from "../images/icons/zendesk.svg";

export default function ConnectedApps() {
  const { currentOrganization, updateOrganization } = useAuth();

  const disconnectGoogleDrive = async () => {
    const result = await client.disconnectGoogleDrive(currentOrganization._id);
    if (result.ok) {
      updateOrganization(result.data);
    }
  };

  const disconnectConfluence = async () => {
    const result = await client.disconnectConfluence(currentOrganization._id);
    if (result.ok) {
      updateOrganization(result.data);
    }
  };

  const disconnectOneDrive = async () => {
    const result = await client.disconnectOneDrive(currentOrganization._id);
    if (result.ok) {
      updateOrganization(result.data);
    }
  };

  const disconnectSharepoint = async () => {
    const result = await client.disconnectSharepoint(currentOrganization._id);
    if (result.ok) {
      updateOrganization(result.data);
    }
  };

  const disconnectZendesk = async () => {
    const result = await client.disconnectZendesk(currentOrganization._id);
    if (result.ok) {
      updateOrganization(result.data);
    }
  };

  return (
    <div className="main-wrapper">
      <Header></Header>

      <div className="content-wrapper narrow connected-apps">
        <div className="page-title">
          <h1>Connected Apps</h1>
        </div>

        <div className={classNames("app-card", currentOrganization.slackConnected || "disabled")}>
          <div className="card-header">
            <img src={IconSlack} alt={IconSlack} />
            <h3>Slack</h3>
          </div>
          <div className="card-info">
            <p>
              {currentOrganization.slackConnected ? (
                <>
                  Connected with:
                  <br />{" "}
                  <a href={`https://${currentOrganization.slackConnected}.slack.com`} target="_blank" rel="noreferrer">
                    {currentOrganization.slackConnected}
                  </a>
                </>
              ) : (
                "Not connected"
              )}
            </p>

            {currentOrganization.slackConnected ? (
              <p className="small" style={{ textAlign: "right" }}>
                To disconnect: Open Slack
                <br />
                Go to “Apps” &gt; “WiseOx” &gt; Configuration,
                <br />
                click on Remove App button.
              </p>
            ) : (
              <a
                className="slack-button button small"
                target="_blank"
                rel="noreferrer"
                href="https://slack.com/oauth/v2/authorize?client_id=5036399419237.6237990470582&scope=commands,chat:write,chat:write.customize,groups:history,groups:read,groups:write,channels:history,channels:manage,channels:read,im:history&user_scope=channels:write"
              >
                <img src={IconSlack} alt={IconSlack} /> Connect Slack
              </a>
            )}
          </div>
        </div>

        <div className={classNames("app-card", currentOrganization.googleDriveConnected || "disabled")}>
          <div className={"card-header"}>
            <img src={IconGoogleDrive} alt={IconGoogleDrive} />
            <h3>Google Drive</h3>
          </div>
          <div className="card-info">
            <p>
              {currentOrganization.googleDriveConnected ? (
                <>
                  Connected with:
                  <br /> {currentOrganization.googleDriveConnected}
                </>
              ) : (
                "Not connected"
              )}
            </p>

            {currentOrganization.googleDriveConnected && (
              <button className="small" onClick={() => disconnectGoogleDrive()}>
                Disconnect
              </button>
            )}
          </div>
        </div>

        <div className={classNames("app-card", currentOrganization.confluenceConnected || "disabled")}>
          <div className="card-header">
            <img src={IconConfluence} alt={IconConfluence} />
            <h3>Atlassian Confluence</h3>
          </div>
          <div className="card-info">
            <p>
              {currentOrganization.confluenceConnected ? (
                <>
                  Connected with:
                  <br /> {currentOrganization.confluenceConnected}
                </>
              ) : (
                "Not connected"
              )}
            </p>

            {currentOrganization.confluenceConnected && (
              <button className="small" onClick={() => disconnectConfluence()}>
                Disconnect
              </button>
            )}
          </div>
        </div>

        <div className={classNames("app-card", currentOrganization.oneDriveConnected || "disabled")}>
          <div className="card-header">
            <img src={IconOneDrive} alt={IconOneDrive} />
            <h3>Microsoft OneDrive</h3>
          </div>
          <div className="card-info">
            <p>
              {currentOrganization.oneDriveConnected ? (
                <>
                  Connected with:
                  <br /> {currentOrganization.oneDriveConnected}
                </>
              ) : (
                "Not connected"
              )}
            </p>

            {currentOrganization.oneDriveConnected && (
              <button className="small" onClick={() => disconnectOneDrive()}>
                Disconnect
              </button>
            )}
          </div>
        </div>

        <div className={classNames("app-card", currentOrganization.sharepointConnected || "disabled")}>
          <div className="card-header">
            <img src={IconSharepoint} alt={IconSharepoint} />
            <h3>OneDrive Business & Sharepoint</h3>
          </div>
          <div className="card-info">
            <p>
              {currentOrganization.sharepointConnected ? (
                <>
                  Connected with:
                  <br /> {currentOrganization.sharepointConnected}
                </>
              ) : (
                "Not connected"
              )}
            </p>

            {currentOrganization.sharepointConnected && (
              <button className="small" onClick={() => disconnectSharepoint()}>
                Disconnect
              </button>
            )}
          </div>
        </div>

        <div className={classNames("app-card", currentOrganization.zendeskConnected || "disabled")}>
          <div className="card-header">
            <img src={IconZendesk} alt={IconZendesk} />
            <h3>Zendesk</h3>
          </div>
          <div className="card-info">
            <p>
              {currentOrganization.zendeskConnected ? (
                <>
                  Connected with:
                  <br />{" "}
                  <a href={`https://${currentOrganization.zendeskConnected}.zendesk.com`} target="_blank" rel="noreferrer">
                    {currentOrganization.zendeskConnected}
                  </a>
                </>
              ) : (
                "Not connected"
              )}
            </p>

            {currentOrganization.zendeskConnected && (
              <button className="small" onClick={() => disconnectZendesk()}>
                Disconnect
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
