import React from "react";
import classNames from "classnames";
import Loader from "../Loader";

export default function TableChart({ data, className, title, showIndex, hideCount }) {
  return (
    <div className={classNames("chart", "table-chart", className && className)}>
      <h4>{title}</h4>

      <div className="list dark">
        <ul>
          {data ? (
            data.length > 0 && data.reduce((acc, curr) => acc + curr.count || 1, 0) > 0 ? (
              data.map((item, i) => (
                <li className="list-item" key={i}>
                  {showIndex && <div className="meta light">{i + 1}</div>}
                  <div className="list-item-name">{item._id || "Unknown"}</div>
                  {!hideCount && <div className="meta light">{item.count && item.count}</div>}
                </li>
              ))
            ) : (
              <h5 className="no-data">No data yet</h5>
            )
          ) : (
            <Loader classNames="dark large"></Loader>
          )}
        </ul>
      </div>
    </div>
  );
}
