import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import FileInput from "./FileInput";

export default forwardRef((props, ref) => {
  const [isDragged, setIsDragged] = useState(false);
  const fileInputRef = useRef();

  useImperativeHandle(ref, () => ({
    reset: () => {
      fileInputRef.current.value = null;
    },
  }));

  const dropHandler = (e) => {
    e.preventDefault();
    props.fileHandler(e.dataTransfer.files);
  };

  const fileInputHandler = (e) => {
    props.fileHandler(e.target.files);
  };

  const dragOverHandler = (e) => {
    e.preventDefault();
    setIsDragged(true);
  };

  const dragLeaveHandler = () => {
    setIsDragged(false);
  };

  return (
    <div
      className={`dnd-wrapper ${isDragged ? "dragged" : ""}`}
      onDrop={dropHandler}
      onDragOver={dragOverHandler}
      onDragLeave={dragLeaveHandler}
    >
      <h5>Drag in files to upload</h5>
      <span className="meta">or</span>
      <FileInput
        inputRef={fileInputRef}
        label="Browse Files"
        disabled={props.disabled}
        onChange={fileInputHandler}
        multiple={true}
      ></FileInput>
    </div>
  );
});
