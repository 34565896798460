import React, { useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { useFormik } from "formik";
import { useAuth } from "../hooks/useAuth";
import useQuery from '../hooks/useQuery';

import BgImg from "../images/sign-in-bg.png";
import Logo from "../images/logo-wiseox.svg";

const initialFormState = {
  username: "",
  password: "",
};

export function Login() {
  let history = useHistory();
  const query = useQuery();
  let { logIn, loading, isAuthenticated, handleError, handleSignupError, hasError } = useAuth();
  let { handleChange, handleSubmit, setFieldValue, values, errors, touched } = useFormik({
    initialValues: initialFormState,
    onSubmit: handleSubmission,
  });

  useEffect(() => {
    if (query.get("email")) {
      setFieldValue("username", query.get("email"));
    }
  }, [query, setFieldValue]);

  useEffect(() => {
    handleError(null);
    handleSignupError(null);
  }, [handleError, handleSignupError]);

  useEffect(() => {
    if (isAuthenticated) history.push("/");
  }, [isAuthenticated, history]);

  async function handleSubmission(values, { setSubmitting }) {
    setSubmitting(false);
    logIn(values.username, values.password, query.get("invitationToken"));
  }

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="auth-wrapper" style={{ backgroundImage: `url(${BgImg})` }}>
      <div className="logo-wrapper">
        <img className="logo" src={Logo} alt="WiseOx Logo" />
      </div>

      <div className="signin-form-wrapper">
        <div className="signin-form">
          <h4>Sign into your account</h4>
          <p className="small signup-link-mobile">
            Don't have an account? <NavLink to="/signup">Sign Up</NavLink>
          </p>

          <div className="input-group">
            <input
              type="email"
              name="username"
              placeholder="Email address"
              value={values.username}
              onChange={handleChange}
              tabIndex="1"
            />
            {errors.username && touched.username ? <div className="error">{errors.username}</div> : null}
          </div>

          <div className="input-group">
            <input
              type="password"
              required
              name="password"
              placeholder="Password"
              value={values.password}
              onChange={handleChange}
              tabIndex="2"
              onKeyDown={_handleKeyDown}
            />
            {errors.password && touched.password ? <div className="error">{errors.password}</div> : null}
          </div>

          <button className="positive" disabled={loading} onClick={handleSubmit} tabIndex="3">
            Sign In
          </button>
          {hasError ? <p className="error">Invalid username or password</p> : null}

          <NavLink className="forgot-link" to="/forgot-password">
            Forgot Password?
          </NavLink>
        </div>

        <div className="terms-links">
          <a href="https://wiseox.com/legal/terms-of-service" className="meta" target="_blank" rel="noreferrer">
            Terms of Service
          </a>
        </div>
      </div>

      <div className="signup-cta-wrapper">
        <div className="signup-cta">
          <h1>Your Company AI Assistant</h1>
          <p className="large">
            Automate tasks and speed up access to information with trained and personalized AI assistants called "mascots".
          </p>

          {query.has("invitationToken") ? (
            <NavLink className="button action large" to={`/signup?invitationToken=${query.get("invitationToken")}`}>
              Sign Up
            </NavLink>
          ) : (
            <NavLink className="button action large" to="/signup">
              Sign Up
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
}
