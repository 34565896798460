import React from "react";
import { NavLink } from "react-router-dom";

import BgImg from "../images/sign-in-bg.png";
import Logo from "../images/logo-wiseox.svg";

export function NotFound() {
  return (
    <div className="auth-wrapper" style={{ backgroundImage: `url(${BgImg})` }}>
      <div className="logo-wrapper">
        <img className="logo" src={Logo} alt="WiseOx Logo" />
      </div>

      <div className="signup-form">
        <h1>Page Not Found</h1>
        <NavLink className="button" to="/">
          Go Home
        </NavLink>
      </div>
    </div>
  );
}
