import React from "react";
import "./scss/styles.scss";
import "./icons/icons.css"; // Icon font imported separately from styles
import { Switch, Route, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./hooks/useAuth";
import { PrivateRoute } from "./components/PrivateRoute";
import Loader from "./components/Loader";
import { EmbedChat } from "./embed";
import BobbieImg from "./images/bobbie.png";

// Pages
import Home from "./pages/Home";
import Account from "./pages/Account";
import Administrators from "./pages/Administrators";
import ConnectedApps from "./pages/ConnectedApps";
import ChangePassword from "./pages/ChangePassword";
import { Login } from "./pages/Login";
import { Connect } from "./pages/Connect";
import { Verify } from "./pages/Verify";
import { SignupConfirmed } from "./pages/SignupConfirmed";
import { Logout } from "./pages/Logout";
import { SignUp } from "./pages/Signup";
import { Invitation } from "./pages/Invitation";
import { MissingOrg } from "./pages/MissingOrg";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword";
import { NotFound } from "./pages/NotFound";
import { Blocked } from "./pages/Blocked";
import MascotChat from "./pages/MascotChat";
import MascotChatReadOnly from "./pages/MascotChatReadOnly";
import EditMascotLayout from "./pages/EditMascotLayout";
import Debugger from "./components/Debugger";
import Insights from "./pages/Insights";
import MascotInsightsLayout from "./pages/MascotInsightsLayout";

// Providers
import { ProvideConversations } from "./hooks/useConversations";
import { ProvideMessages } from "./hooks/useMessages";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(React.useEffect, useLocation),
        tracePropagationTargets: ["localhost", /^https:\/\/staging\.wiseox\.ai/, /^https:\/\/wiseox\.ai/],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
const SentryRoutes = Sentry.withSentryRouting(Route);

export default function App() {
  const { loading: loadingAuth, isAuthenticated } = useAuth();

  if (process.env.REACT_APP_ENABLE_WORK_ING_PROGRESS_PAGE === "true") {
    return <Blocked></Blocked>;
  }

  return loadingAuth ? (
    <Loader classNames="dark app-loader" />
  ) : (
    <>
      <SentryRoutes>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/connect" exact component={Connect} />
          <Route path="/signup" component={SignUp} />
          <Route path="/signupConfirmed" component={SignupConfirmed} />
          <Route path="/verify" component={Verify} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/set-password" component={ResetPassword} />
          <Route path="/passwordReset" component={ResetPassword} />
          <Route path="/invitation" component={Invitation} />
          <Route path="/missing-org" component={MissingOrg} />

          <PrivateRoute path="/" exact>
            <Home />
          </PrivateRoute>

          <PrivateRoute path="/account">
            <Account />
          </PrivateRoute>

          <PrivateRoute path="/administrators">
            <Administrators />
          </PrivateRoute>

          <PrivateRoute path="/connectedApps">
            <ConnectedApps />
          </PrivateRoute>

          <PrivateRoute path="/changePassword">
            <ChangePassword />
          </PrivateRoute>

          <PrivateRoute path="/logout">
            <Logout />
          </PrivateRoute>

          <PrivateRoute path="/mascot/:mascotId/edit">
            <EditMascotLayout />
          </PrivateRoute>

          <PrivateRoute path="/insights">
            <Insights />
          </PrivateRoute>

          <PrivateRoute path="/mascot/:mascotId/insights">
            <MascotInsightsLayout />
          </PrivateRoute>

          <Route path="/chat/:shareToken">
            <ProvideConversations>
              <MascotChatReadOnly />
            </ProvideConversations>
          </Route>

          <Route path="/mascot/:mascotId/chat/:conversationId">
            <ProvideConversations>
              <ProvideMessages>
                <MascotChat />
              </ProvideMessages>
            </ProvideConversations>
          </Route>

          <Route path="/mascot/:mascotId/chat">
            <ProvideConversations>
              <ProvideMessages>
                <MascotChat />
              </ProvideMessages>
            </ProvideConversations>
          </Route>

          <Route path="/mascot/:mascotId">
            <ProvideConversations>
              <ProvideMessages>
                <MascotChat />
              </ProvideMessages>
            </ProvideConversations>
          </Route>

          <PrivateRoute path="/*">
            <NotFound />
          </PrivateRoute>
        </Switch>
      </SentryRoutes>
      <ToastContainer position="bottom-right" theme="dark" />

      {isAuthenticated && process.env.REACT_APP_BOB_ID && window.self === window.top && (
        <EmbedChat mascotId={process.env.REACT_APP_BOB_ID} label="Ask Bobbie" iconImage={BobbieImg}></EmbedChat>
      )}
      {process.env.REACT_APP_ENABLE_DEBUEGGER === "true" && <Debugger></Debugger>}
    </>
  );
}
