import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { client } from "../services/api";
import { useAuth } from "../hooks/useAuth";

const initialFormState = {
  name: "",
  description: "",
};

const sheetSchema = Yup.object().shape({
  name: Yup.string().required("Name required"),
  description: Yup.string().required("Description required"),
});

export default function CreateMascot() {
  const history = useHistory();
  const { currentOrganization } = useAuth();

  const handleSubmission = async (values) => {
    const res = await client.createMascot({ ...values, image: null, organization: currentOrganization });
    if (res.ok) {
      history.push(`/mascot/${res.data._id}/edit/info`);
    }
  };

  // eslint-disable-next-line
  const { handleChange, handleSubmit, values, setFieldValue, errors, touched } = useFormik({
    initialValues: initialFormState,
    validationSchema: sheetSchema,
    onSubmit: handleSubmission,
  });

  return (
    <div className="content-wrapper col-2">
      <div className="content-col">
        <h2 className="modal-title">New Mascot</h2>
        <div className="input-group">
          <label>Mascot Name</label>
          <input type="text" name="name" value={values.name} onChange={handleChange} placeholder="Give your mascot a name" />
          {errors.name && touched.name ? <span className="error">{errors.name}</span> : null}

          {/* POC: generate content from Bob */}
          {/* {process.env.REACT_APP_BOB_ID && (
            <EmbedGenerateButton
              label="Suggest"
              prompt="Create a mascot name for me, respond only with the requested value"
              response={onGeneratedName}
              customClass="button generate-btn xsmall"
            ></EmbedGenerateButton>
          )} */}
        </div>

        <div className="input-group">
          <label>Description</label>

          <TextareaAutosize
            type="text"
            maxRows={5}
            name="description"
            value={values.description}
            onChange={handleChange}
            placeholder="e.g. 'Sales training mascot'"
          />
          {errors.description && touched.description ? <span className="error">{errors.description}</span> : null}

          {/* POC: generate content from Bob */}
          {/* {process.env.REACT_APP_BOB_ID && (
            <EmbedGenerateButton
              label="Suggest"
              prompt="Create a mascot description for me, respond only with the requested value"
              response={onGeneratedDesc}
              customClass="button generate-btn xsmall"
            ></EmbedGenerateButton>
          )} */}
        </div>

        <button className="large action wide" onClick={handleSubmit}>
          Get Started
        </button>
      </div>
      <div className="content-col">
        <h5>What can I do with a mascot?</h5>
        <p className="small">
          You can train a mascot on your company data to make it a subject matter expert. This works for both internal and
          external use cases.
        </p>
        <p className="small">
          Mascots are often used as an internal knowledgebase for sales training, marketing, and employee onboarding.
        </p>
        <p className="small">
          They can also be useful to provide a unique experience to your customers should they need to learn about your products
          or services.
        </p>
      </div>
    </div>
  );
}
