import React, { useEffect, useState, useContext, createContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useAuth } from "./useAuth";
import { useSocket } from "./ws";
import { servicesClient } from "../services/servicesApi";

const currentEditorsContext = createContext();

function useProvideEditorsContext() {
  const { mascotId } = useParams();
  const { socket } = useSocket();
  const { currentUser } = useAuth();
  let location = useLocation();
  const [currentEditors, setCurrentEditors] = useState({});

  useEffect(() => {
    servicesClient.getCurrentEditors(mascotId).then(result => {
      if (result.ok) {
        setCurrentEditors((prev) => {
          let updatedEditors = { ...prev };
          result.data.filter(editors => editors.userId !== currentUser._id).forEach((item) => {
            updatedEditors[item.userId] = item;
          });
          return updatedEditors;
        });
      }
    });
  }, [currentUser, mascotId]);

  useEffect(() => {
    socket.emit("editing", { mascotId, userId: currentUser._id, path: location.pathname });
  }, [location, mascotId, socket, currentUser]);

  useEffect(() => {
    return () => {
      socket && socket.emit("editing", { mascotId, userId: currentUser._id, disconnected: true });
    };
    // eslint-disable-next-line
  }, []);

  function onCurrentEditors(data) {
    if (data.disconnected) {
      setCurrentEditors((prev) => {
        const updatedEditors = { ...prev };
        delete updatedEditors[data.userId];
        return updatedEditors;
      });
    } else {
      setCurrentEditors((prev) => ({
        ...prev,
        [data.userId]: data
      }));
    }
  }

  useEffect(() => {
    socket && socket.on("currentEditors", onCurrentEditors);
    return () => {
      socket && socket.off("currentEditors", onCurrentEditors);
    };
  }, [socket]);

  return {
    currentEditors
  };
}

export function ProvideCurrentEditors({ children }) {
  const currentEditors = useProvideEditorsContext();
  return <currentEditorsContext.Provider value={currentEditors}>{children}</currentEditorsContext.Provider>;
}

export const useCurrentEditors = () => {
  return useContext(currentEditorsContext);
};
