// Only local subfolder imports
import EmbedChatComponent from "./EmbedChat";
import EmbedGenerateButtonComponent from "./EmbedGenerateButton";
import "./style-embed-chat.scss";

export function EmbedChat(props) {
  return <EmbedChatComponent {...props}></EmbedChatComponent>;
}

export function EmbedGenerateButton(props) {
  return <EmbedGenerateButtonComponent {...props}></EmbedGenerateButtonComponent>;
}
