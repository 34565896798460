import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useEditMascot } from "../hooks/useEditMascot";

export default function EditMascotBehaviors() {
  const { mascotDraft, saveDraft } = useEditMascot();

  const handleCustomBehaviorsChange = (i, value) => {
    if (typeof mascotDraft.custom_behaviors === "string" || mascotDraft.custom_behaviors instanceof String) {
      let old_value = mascotDraft.custom_behaviors;
      value = old_value + " # " + value;
      mascotDraft.custom_behaviors = {};
    }
    mascotDraft.custom_behaviors["behavior_" + i] = value;
    saveDraft(mascotDraft);
  };

  return (
    <div>
      <div className="edit-mascot-header">
        <h1>Behaviors</h1>
      </div>

      <div className="content-wrapper full-width">
        <p>
          Behaviors are guidelines to instruct the mascot to respond in certain ways. This is totally freeform and not required.
          These guidelines should be very descriptive and actionable for the mascot. Mascots will do their best to adhere to these
          guidelines and act autonomously. Please write your behaviors in the <b>second person</b> point of view.
        </p>
        <p className="meta warning">Note: Leave any field blank if it is not applicable.</p>

        {mascotDraft && mascotDraft.custom_behaviors && (
          <div className="behaviors-fields">
            <div className="behavior-field">
              <div className="input-group">
                <label>
                  What is the purpose, role, or goal of the mascot? Be specific about certain behaviors and responses.
                </label>
                <TextareaAutosize
                  maxRows={30}
                  value={mascotDraft.custom_behaviors.behavior_1}
                  onChange={(e) => handleCustomBehaviorsChange(1, e.target.value)}
                  className="transparent small"
                  tabIndex={1}
                />
              </div>
              <p className="meta small">
                Example: <br />
                &quot;You are a helpful sales assistant that is determined to convert users to paying customers. As the front-line
                sales person, you are able to provide helpful insight into product features using concise language. If asked to
                talk to a human, collect their name and email address, and tell them we will be in touch ASAP.&quot;
              </p>
            </div>

            <div className="behavior-field">
              <div className="input-group">
                <label>What questions or actions should the mascot perform in order to identify a need / pain point?</label>
                <TextareaAutosize
                  maxRows={30}
                  className="transparent small"
                  tabIndex={2}
                  value={mascotDraft.custom_behaviors.behavior_2}
                  onChange={(e) => handleCustomBehaviorsChange(2, e.target.value)}
                />
              </div>
              <p className="meta small">
                Example: <br />
                &quot;You should attempt to understand the user's need for AI by asking what platforms they currently use. When
                they answer, follow up by asking why they're looking for other solutions.&quot;
              </p>
            </div>

            <div className="behavior-field">
              <div className="input-group">
                <label>
                  If necessary, describe a workflow the mascot should follow. Include how it should trigger, and describe each
                  step in the process.
                </label>
                <TextareaAutosize
                  maxRows={30}
                  className="transparent small"
                  tabIndex={3}
                  value={mascotDraft.custom_behaviors.behavior_3}
                  onChange={(e) => handleCustomBehaviorsChange(3, e.target.value)}
                />
              </div>
              <p className="meta small">
                Example: <br />
                &quot;After the 5th interaction with the user, you should ask how they like the experience so far on a scale of
                1-5. When they answer, ask if there's anything we could do better. From there, continue interacting as normal. If
                the user asks you something unrelated to our offering, say that you are not trained on answering that kind of
                question. Follow up by asking if they have any more sales related questions.&quot;
              </p>
            </div>

            <div className="behavior-field">
              <div className="input-group">
                <label>
                  Describe alternative interactions. E.g. quizzes, collecting data, or booking a meeting with a human. Include
                  links as necessary.
                </label>
                <TextareaAutosize
                  maxRows={30}
                  className="transparent small"
                  tabIndex={4}
                  value={mascotDraft.custom_behaviors.behavior_4}
                  onChange={(e) => handleCustomBehaviorsChange(4, e.target.value)}
                />
              </div>
              <p className="meta small">
                Example: <br />
                &quot;Randomly throughout the conversation, ask the user if they'd like to take a 4 question quiz in order to
                receive a 15% discount on their first month of paid usage. You should only ever perform this once. Use your
                knowledge to test them about our platform. Score their answers. If they get 4 out of 4, inform them that a Sales
                Associate will be in touch to ensure they get a discount.&quot;
              </p>
            </div>

            <div className="behavior-field">
              <div className="input-group">
                <label>
                  Include any other instruction you wish the mascot to follow. Please use the second person point of view.
                </label>
                <TextareaAutosize
                  maxRows={30}
                  className="transparent small"
                  tabIndex={5}
                  value={mascotDraft.custom_behaviors.behavior_5}
                  onChange={(e) => handleCustomBehaviorsChange(5, e.target.value)}
                />
              </div>
              {/* <p className="meta small">Example: <br />&quot;Ultimately you should get the user to book a call with a Sales Associate. Do not be too persistant with this, but after a few messages you can ask if they'd like to book a meeting using this link: https://example.com/meeting.&quot;</p> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
