import React, { useState, useEffect, useCallback } from "react";
import Modal from "./Modal";
import Loader from "./Loader";
import { useAuth } from "../hooks/useAuth";
import { servicesClient } from "../services/servicesApi";
import TreeViewFilePicker from "./TreeViewFilePicker";
import { FileIcon } from "../components/FileIcon";

function SharepointSitePicker({ isShown, setShown, onSelect, subdomain }) {
  const { currentOrganization } = useAuth();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [selectedIds, setSelectedIds] = useState([]);
  const [exportedSelectedIds, setExportedSelectedIds] = useState([]);

  const initialData = [
    {
      name: subdomain,
      id: 0,
      children: [],
      parent: null,
    },
  ];

  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (isShown) {
      setLoading(true);
      const sectionsPromise = servicesClient.getSharepointSites(currentOrganization._id).then((result) => {
        result.ok && setData((value) => updateTreeSites(value, result.data));
      });
      Promise.all([sectionsPromise]).then(() => setLoading(false));
    }
  }, [isShown, currentOrganization]);

  const _onSelect = useCallback(
    ({ treeState }) => {
      setExportedSelectedIds(
        data
          .filter((d) => treeState.selectedIds.has(d.id))
          .map((d) => ({ siteId: d.siteId, pageId: d.pageId, name: d.name, type: d.type }))
      );
    },
    [data]
  );

  const updateTreeSites = (list, sites) => {
    for (let s of sites) {
      if (!list.find((node) => node.siteId === s.id)) {
        let id = list.length;
        list.push({
          name: s.displayName,
          id: id,
          children: [],
          parent: 0,
          isBranch: true,
          type: "web",
          siteId: s.id,
          selectable: false,
        });
        list[0].children.push(id);
      }
    }

    return [...list];
  };

  const updateTreeSitePages = (list, parentNode, pages) => {
    for (let p of pages) {
      if (!list.find((node) => node.pageId === p.id)) {
        let id = list.length;
        list.push({
          name: p.name,
          id: id,
          children: [],
          parent: parentNode,
          isBranch: false,
          type: "page",
          pageId: p.id,
          selectable: true,
          siteId: p.parentReference.siteId,
        });

        list[parentNode].children.push(id);
      }
    }

    list[parentNode].isBranch = false;

    return [...list];
  };

  const loadData = useCallback(
    async ({ element }) => {
      if (element.type === "web" && element.isBranch) {
        const result = await servicesClient.getSharepointSitePages(currentOrganization._id, element.siteId);
        result.ok && setData((value) => updateTreeSitePages(value, element.id, result.data));
      }
    },
    [currentOrganization]
  );

  return (
    <Modal
      title="SharePoint Pages"
      isOpen={!!isShown}
      close={() => setShown(false)}
      size="large"
      className="picker-modal"
      action={
        <>
          <button
            className="action"
            onClick={() => {
              onSelect(exportedSelectedIds);
              setShown(false);
            }}
          >
            Select Content
          </button>
          <button onClick={() => setShown(false)}>Cancel</button>
        </>
      }
    >
      {loading ? (
        <Loader />
      ) : (
        <TreeViewFilePicker
          data={data}
          disableCheck={true}
          loadData={loadData}
          selectedIds={selectedIds}
          onSelect={_onSelect}
          renderItem={(element) => <span className="name"><FileIcon doc={element} type='sharepoint' /> {element.name}</span>}
        />
      )}
    </Modal>
  );
}

export default SharepointSitePicker;
