import React, { useState, useEffect, useContext, createContext } from "react";
import { client } from "../services/api";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { useAuth } from "./useAuth";

const mascotContext = createContext();

function useProvideMascot() {
  const { pathname } = useLocation();
  const match = matchPath(pathname, { path: "/mascot/:mascotId" });
  const [mascot, setMascot] = useState({});
  const [mascotId, setMascotId] = useState();
  const { currentUser, authToken } = useAuth();

  useEffect(() => {
    const fetchMascot = async (mascotId) => {
      let mascotData = await client.getMascot(mascotId);
      if (mascotData.data) {
        setMascot(mascotData.data);
      }
    };

    if (match && match.params && match.params.mascotId) {
      if (match.params.mascotId !== mascotId) {
        if (!authToken || (authToken && currentUser)) {
          setMascotId(match.params.mascotId);
          fetchMascot(match.params.mascotId);
        }
      }
    } else if (mascotId) {
      setMascotId();
      setMascot({});
    }
  }, [match, authToken, currentUser, mascotId]);

  return {
    mascot,
    setMascot,
    mascotId,
  };
}

export function ProvideMascot({ children }) {
  const mascot = useProvideMascot();
  return <mascotContext.Provider value={mascot}>{children}</mascotContext.Provider>;
}

export const useMascot = () => {
  return useContext(mascotContext);
};
