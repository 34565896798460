import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import { servicesClient } from "../services/servicesApi";
import { MascotDataHeader } from "../components/MascotDataHeader";
import { ListDataFile } from "../components/ListDataFile";
import { useTasks } from "../hooks/useTasks";
import Loader from "../components/Loader";
import { useEditMascot } from "../hooks/useEditMascot";
import WebFilePicker from "../components/WebFilePicker";
import Modal from "../components/Modal";
import IconGlobe from "../images/icons/globe.svg";

export default function EditMascotDataWeb() {
  const { clientId } = useAuth();
  const { mascotId } = useParams();
  const { embeddingTask, addTask } = useTasks();
  const { handleDeleteUpload, handleRefreshingUpload, mascot } = useEditMascot();
  const [websitePath, setWebsitePath] = useState("");
  const [isShownFilePicker, setShownFilePicker] = useState(false);
  const [showCrawlerModal, setShowCrawlerModal] = useState(false);

  const handleRefreshUrl = async (upload) => {
    const result = await servicesClient.dataUpdateWeb(mascotId, upload._id, clientId);
    if (!result.ok) {
      toast.error("Crawling error!");
    } else {
      addTask(result.data[0]);
      handleRefreshingUpload(upload._id);
    }
  };

  const getFileAction = (doc) => (
    <>
      {doc.refreshing ? (
        <Loader />
      ) : (
        <>
          {!embeddingTask && <i className="icon-reload" onClick={() => handleRefreshUrl(doc)}></i>}
          {!embeddingTask && <i className="icon-remove" onClick={() => handleDeleteUpload(doc._id)}></i>}
        </>
      )}
    </>
  );

  const getURLAction = (doc) => (
    <a href={doc.url} rel="noreferrer" target="_blank" className="icon-btn">
      <i className="icon-external-link"></i>
    </a>
  );

  const handleCloseModal = () => {
    setShownFilePicker(false);
    setShowCrawlerModal(false);
    setWebsitePath("");
  };

  const crawlWebsite = () => {
    setShownFilePicker(true);
  };

  return (
    <div>
      <MascotDataHeader></MascotDataHeader>

      <div className="data-pane content-wrapper full-width">
        <h3>
          <img src={IconGlobe} alt={IconGlobe}></img> Website URLs
        </h3>

        <p className="meta warning">
          Note: URLs must be publicly accessible and not behind a login. Certain sites may block this feature.
        </p>

        {mascot.data && mascot.data?.uploads && mascot.data?.uploads.filter((doc) => doc.type === "web").length > 0 ? (
          <ListDataFile
            title="Previously Added URLs"
            searchLabel="Search previously added URLs"
            getFileAction={getFileAction}
            getURLAction={getURLAction}
            showDeleteButton={false}
            showFilterType={false}
            disabled={!!embeddingTask}
            nameField="url"
            files={mascot.data && mascot.data?.uploads && mascot.data?.uploads.filter((doc) => doc.type === "web").reverse()}
            buttonBar={
              <button disabled={!!embeddingTask} onClick={() => setShowCrawlerModal(true)} className="small">
                Add URL
              </button>
            }
          ></ListDataFile>
        ) : (
          <button disabled={!!embeddingTask} onClick={() => setShowCrawlerModal(true)} className="small">
            Add URL
          </button>
        )}

        <Modal isOpen={showCrawlerModal} close={() => handleCloseModal()} title="Enter a Website URL">
          {/* <p>If there are subpages in the URL you enter, you can select up to 15 pages to add at a time. You can always come back to add more.</p> */}
          <p>
            If there are subpages in your site you want to select, you may need to try your domain <em>with or without</em>{" "}
            "www".
          </p>

          {!isShownFilePicker ? (
            <div className="input-combo over">
              <input
                type="text"
                className="small"
                placeholder="https://example.com"
                value={websitePath}
                onChange={(event) => setWebsitePath(event.target.value)}
              />

              <button disabled={!websitePath} className="small" onClick={crawlWebsite}>
                Next
              </button>
            </div>
          ) : (
            <>
              <div className="meta warning">You can select up to 15 subpages.</div>

              <WebFilePicker
                websitePath={websitePath}
                isShown={isShownFilePicker}
                setShown={setShownFilePicker}
                setShowCrawlerModal={setShowCrawlerModal}
                setWebsitePath={setWebsitePath}
              />
            </>
          )}
        </Modal>
      </div>
    </div>
  );
}
