import React, { useState, useCallback } from "react";
import { toast } from "react-toastify";
import ZendeskFilePicker from "../components/ZendeskFilePicker";
import { MascotDataHeader } from "../components/MascotDataHeader";
import { servicesClient } from "../services/servicesApi";
import { useAuth } from "../hooks/useAuth";
import { useHistory, useLocation } from "react-router-dom";
import useQuery from "../hooks/useQuery";
import { ListDataFile } from "../components/ListDataFile";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import { useTasks } from "../hooks/useTasks";
import { useEditMascot } from "../hooks/useEditMascot";
import IconZendesk from "../images/icons/zendesk.svg";

export default function EditMascotDataZendesk() {
  const { currentOrganization, currentUser } = useAuth();
  const { embeddingTask, addTask, taskList, isUpdating } = useTasks();
  const { mascot, updateDataLastModified } = useEditMascot();
  const [isShownFilePicker, setShownFilePicker] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSubdomainModal, setShowSubdomainModal] = useState(false);
  const [showUpdateLoader, setShowUpdateLoader] = useState(false);
  const [subdomain, setSubdomain] = useState();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const authorizeUser = async () => {
    const result = await servicesClient.authorizeZendesk(currentOrganization._id, window.location.href, subdomain);
    if (result.ok) {
      window.location.href = result.data.authUrl;
    }
  };

  const browse = async () => {
    if (!currentOrganization.zendeskConnected) {
      if (mascot.data?.uploads.filter((doc) => doc.type === "zendesk").length > 0) {
        setShowConfirmModal(true);
      } else {
        authorizeUser();
      }
    } else {
      setShownFilePicker(true);
    }
  };

  const onSelect = useCallback(
    async (files) => {
      const filteredFiles = files.filter((file) => {
        if (
          mascot.data?.uploads.filter((doc) => doc.type === "zendesk").some((u) => u.name === file.name) ||
          taskList
            .filter((doc) => doc.type === "addZendeskTask" && doc.status === "queued")
            .some((u) => u?.meta?.filename === file.name)
        ) {
          toast.error(() => (
            <>
              <b>{file.name}</b>: Duplicate files cannot be uploaded.
            </>
          ));
          return false;
        }
        return true;
      });
      for (let file of filteredFiles) {
        const result = await servicesClient.dataAddZendesk(mascot._id, file, currentUser._id);
        if (!result.ok || result.data.error) {
          console.log("Upload Error! " + result.originalError);
          toast.error(() => (
            <>
              <b>{file.name}</b>: {String(result.originalError)}.
            </>
          ));
          return;
        }
        addTask(result.data);
      }
    },
    [mascot, currentUser, addTask, taskList]
  );

  const checkUpdates = async () => {
    setShowUpdateLoader(true);
    const result = await servicesClient.dataCheckUpdatesZendesk(mascot._id);
    setShowUpdateLoader(false);
    if (result.ok) {
      for (const [key, value] of Object.entries(result.data)) {
        value === null &&
          toast.error(() => (
            <>
              <b>Cannot update file:</b> "{mascot.data?.uploads.find((u) => u._id === key)?.name}" was deleted from Zendesk.
            </>
          ));
      }
      updateDataLastModified(result.data);
    }
  };

  const handleRefreshDoc = async (upload) => {
    let pendingUpdateTask = isUpdating(upload);
    if (!pendingUpdateTask) {
      const result = await servicesClient.dataUpdateZendesk(mascot._id, upload._id, currentUser._id);
      result.ok &&
        addTask({ ...result.data, meta: { filename: upload.name, nodeType: upload.nodeType, zendeskId: upload.zendeskId } });
    } else {
      addTask({
        status: "failed",
        type: "zendesk",
        meta: {
          error: "Update already in progress",
          filename: pendingUpdateTask.meta.filename,
          mimeType: pendingUpdateTask.meta.mimeType,
        },
      });
    }
  };

  const getFileAction = (doc) => (
    <>
      {!embeddingTask && doc.lastModified > doc.lastUpdate && (
        <i
          className="icon-reload"
          onClick={() => {
            handleRefreshDoc(doc);
          }}
        ></i>
      )}
    </>
  );

  const getFileActionPermanent = (doc) => (
    <>
      {!embeddingTask && doc.lastModified > doc.lastUpdate && doc.zendeskAccount === currentOrganization.zendeskConnected && (
        <span className="meta small warning">This file has changes, please update</span>
      )}
      {!embeddingTask && doc.zendeskAccount !== currentOrganization.zendeskConnected && (
        <span className="meta small">Not synced (account changed)</span>
      )}
    </>
  );

  const docsToUpdate = (docs) => {
    return docs.filter(
      (doc) =>
        doc.type === "zendesk" && doc.lastModified > doc.lastUpdate && doc.zendeskAccount === currentOrganization.zendeskConnected
    );
  };

  return (
    <div>
      <MascotDataHeader></MascotDataHeader>

      <div className="data-pane content-wrapper full-width">
        {!currentOrganization.zendeskConnected ? (
          <>
            <h3>
              <img src={IconZendesk} alt={IconZendesk}></img> Connect Zendesk
            </h3>

            <p>Connect your Zendesk account to sync content from Zendesk to your mascot.</p>
          </>
        ) : (
          <>
            <h3>
              <img src={IconZendesk} alt={IconZendesk}></img> Zendesk Files
            </h3>
          </>
        )}

        {mascot.data && mascot.data?.uploads && mascot.data?.uploads.filter((doc) => doc.type === "zendesk").length > 0 ? (
          <ListDataFile
            title="Uploaded Files"
            searchLabel="Search files"
            getFileAction={getFileAction}
            getFileActionPermanent={getFileActionPermanent}
            disabled={!!embeddingTask}
            handleRefreshDoc={handleRefreshDoc}
            docsToUpdate={docsToUpdate}
            buttonBar={
              <>
                <button
                  className="small"
                  onClick={() => (currentOrganization.zendeskConnected ? browse() : setShowSubdomainModal(true))}
                  disabled={embeddingTask}
                >
                  {currentOrganization.zendeskConnected ? "Browse Files" : "Connect Account"}
                </button>

                {currentOrganization.zendeskConnected && (
                  <button className="small" disabled={showUpdateLoader} onClick={checkUpdates}>
                    {showUpdateLoader && <Loader classNames="small"></Loader>}
                    <span>Check For Updates</span>
                  </button>
                )}
              </>
            }
            files={mascot.data && mascot.data?.uploads && mascot.data?.uploads.filter((doc) => doc.type === "zendesk").reverse()}
          ></ListDataFile>
        ) : (
          <button
            className="small"
            onClick={() => (currentOrganization.zendeskConnected ? browse() : setShowSubdomainModal(true))}
            disabled={embeddingTask}
          >
            {currentOrganization.zendeskConnected ? "Browse Files" : "Connect Account"}
          </button>
        )}

        {isShownFilePicker && (
          <ZendeskFilePicker
            isShown={isShownFilePicker}
            setShown={setShownFilePicker}
            onSelect={onSelect}
            subdomain={currentOrganization.zendeskConnected}
          />
        )}

        <Modal
          title="Connect to Zendesk"
          size="small"
          isOpen={showConfirmModal}
          close={() => setShowConfirmModal(false)}
          action={
            <>
              <button className="action" onClick={authorizeUser}>
                Continue
              </button>
              <button onClick={() => setShowConfirmModal(false)}>Cancel</button>
            </>
          }
        >
          <p>Existing Zendesk documents will not sync if connecting with a different Zendesk account.</p>
        </Modal>

        <Modal
          title="Enter Zendesk Subdomain"
          size="small"
          isOpen={showSubdomainModal}
          close={() => setShowSubdomainModal(false)}
          action={
            <>
              <button
                className="action"
                onClick={() => {
                  setShowSubdomainModal(false);
                  browse();
                }}
              >
                Continue
              </button>
              <button onClick={() => setShowSubdomainModal(false)}>Cancel</button>
            </>
          }
        >
          <p>
            Your subdomain can be found when you log into your Zendesk account, located in the URL before <code>zendesk.com</code>
            . Enter only your subdomain.
          </p>

          <div className="input-combo inline">
            <input
              type="text"
              id="inputField"
              placeholder="myZendeskSite"
              className="small underlined"
              defaultValue={subdomain}
              onChange={(event) => setSubdomain(event.target.value)}
            />
            <p>.zendesk.com</p>
          </div>
        </Modal>
        <Modal
          title={`Authentication Error ${query.get("error")}`}
          isOpen={query.has("error")}
          close={() => history.push(location.pathname)}
        >
          <p>
            Authentication failed with the following error: <br />
            <code>{query.get("error_description")}</code>
          </p>
        </Modal>
      </div>
    </div>
  );
}
