import React from "react";
import { Link } from "react-router-dom";
import MascotImage from "./MascotImage";
import classNames from "classnames";

export function MascotList({ mascots }) {
  return (
    <div className="mascot-list">
      {mascots
        .slice(0)
        .reverse()
        .map((mascot) => {
          return (
            <Link
              className={classNames("mascot-list-item", !mascot.image && "empty")}
              to={`/mascot/${mascot._id}/chat`}
              key={mascot._id}
            >
              <div className="mascot-list-item-info">
                <MascotImage mascot={mascot}></MascotImage>

                <h2>{mascot.name}</h2>
                <p>{mascot.description}</p>

                <div className="stats">
                  <div className="stat-label">{mascot.countSessions || "0"} Sessions</div>
                  <div className="stat-label">
                    {mascot.countUsers || "0"} {mascot.countUsers === 1 ? "User" : "Users"}
                  </div>
                </div>
              </div>

              {mascot.image ? (
                <div className="mascot-list-item-bg" style={{ backgroundImage: `url(${mascot.image})` }}></div>
              ) : (
                <div className="mascot-list-item-bg empty"></div>
              )}
            </Link>
          );
        })}
    </div>
  );
}
