import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { toast } from "react-toastify";

const OneDriveFilePicker = forwardRef(({ onSelect }, ref) => {

  const [port, setPort] = useState(null);
  const [win, setWin] = useState(null);
  const [authToken, setAuthToken] = useState(null);

  const baseUrl = "https://onedrive.live.com/picker";
  const params = {
    sdk: "8.0",
    entry: {
      oneDrive: {
        files: {},
      }
    },
    authentication: {},
    messaging: {
      origin: process.env.REACT_APP_URL_APP,
      channelId: "27"
    },
    typesAndSources: {
      mode: "files",
      pivots: {
        oneDrive: true,
        recent: true,
      },
    },
    selection: {
      mode: 'multiple',
    },
  };

  useEffect(() => {
    const messageListener = async (message) => {
      switch (message.data.type) {
        case "command":
          port.postMessage({
            type: "acknowledge",
            id: message.data.id,
          });
          const command = message.data.data;
          switch (command.command) {
            case "authenticate":
              if (authToken) {
                port.postMessage({
                  type: "result",
                  id: message.data.id,
                  data: {
                    result: "token",
                    token: authToken,
                  }
                });
              } else {
                console.error(`Could not get auth token for command: ${JSON.stringify(command)}`);
              }
              break;
            case "close":
              win.close();
              break;
            case "pick":
              onSelect(command.items);
              port.postMessage({
                type: "result",
                id: message.data.id,
                data: {
                  result: "success",
                },
              });
              win.close();
              break;
            default:
              console.warn(`Unsupported command: ${JSON.stringify(command)}`);
              port.postMessage({
                result: "error",
                error: {
                  code: "unsupportedCommand",
                  message: command.command
                },
                isExpected: true,
              });
              break;
          }
          break;
        default:
          break;
      }
    };

    if (port) {
      port.addEventListener("message", messageListener);
      port.start();

      return () => {
        port.removeEventListener("message", messageListener);
      };
    }
  }, [port, authToken, onSelect, win]);

  function launchOneDrivePicker({ accessToken }) {
    setAuthToken(accessToken);
    try {
      if(win) {
        win.close();
      }
      const newWin = window.open('', "OneDrive Picker", "width=800,height=600");
      if (newWin===null) {
        toast.error("Unable to open the popup. Please check your browser settings and allow popups for this site.");
        return
      }
      setWin(newWin);

      const queryString = new URLSearchParams({
        filePicker: JSON.stringify(params),
      });
      const url = `${baseUrl}?${queryString}`;

      const form = newWin.document.createElement("form");
      form.setAttribute("action", url);
      form.setAttribute("method", "POST");
      newWin.document.body.append(form);

      const input = newWin.document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", "access_token");
      input.setAttribute("value", accessToken);
      form.appendChild(input);

      form.submit();

      window.addEventListener("message", (event) => {
        if (event.source && event.source === newWin) {
          const message = event.data;
          if (message.type === "initialize" && message.channelId === params.messaging.channelId) {
            setPort(event.ports[0]);
            event.ports[0].postMessage({ type: "activate" });
          }
        }
      });
    } catch (error) {
      console.error("An error occurred while opening the picker:", error);
      throw error;
    }
  }

  useImperativeHandle(ref, () => ({
    launchOneDrivePicker,
  }));

  return <></>;
});

export default OneDriveFilePicker;