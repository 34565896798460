import React, { useEffect, useState } from "react";
import { client } from "../services/api";
import { useParams } from "react-router-dom";

export default function HeaderInsights({ intervalTime, onSetIntervalTime }) {
  const [data, setData] = useState({});
  const { mascotId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      let data = await client.getStatsSessionsAndUsers(mascotId, intervalTime);
      if (data.ok) {
        setData(data.data);
      }
    };

    if (mascotId) {
      fetchData();
    }
  }, [intervalTime, mascotId]);

  const getIntervalTimeLabel = () => {
    if (intervalTime === "0") {
      return "All History";
    } else if (intervalTime === "1") {
      return "Today";
    } else {
      return intervalTime + " Day History";
    }
  };

  return (
    <div className="insight-header">
      <div className="insight-header-info">
        <div className="insight-header-left">
          <div>
            <b>{getIntervalTimeLabel()}</b>
          </div>
          <div>{data.sessions} Sessions</div>
          <div>{data.users} Users</div>
        </div>
        <div className="insight-header-right">
          <span className="meta small">Time frame</span>

          <div className="select xsmall dark">
            <select value={intervalTime} onChange={(e) => onSetIntervalTime(e.target.value)}>
              <option value={7}>Last 7 Days</option>
              <option value={30}>Last 30 Days</option>
              <option value={0}>All History</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
