import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { useMascot } from "../hooks/useMascot";

// Components
import MascotImage from "./MascotImage";

export default function MascotSidebarInsights(props) {
  const { mascot } = useMascot();
  const sidebarOpen = props.sidebarOpen;
  const setSidebarOpen = props.setSidebarOpen;

  return (
    <div className={classNames("mascot-sidebar", !mascot.image && "bg-empty", sidebarOpen && "open")}>
      {/* Close button for mobile */}
      <div className="icon-btn close-sidebar" onClick={() => setSidebarOpen(!sidebarOpen)}>
        <i className="icon-close"></i>
      </div>
      <div className="mascot-sidebar-content">
        <div className="mascot-header">
          <div className="mascot-image-wrapper">
            <MascotImage mascot={mascot}></MascotImage>
          </div>

          {mascot.name && (
            <div className="mascot-heading">
              <h2>{mascot.name}</h2>
              <p>{mascot.description}</p>
            </div>
          )}

          <NavLink to={`/mascot/${mascot._id}/chat`} className="edit-nav-item">
            <button className="default">Back to Mascot</button>
          </NavLink>
        </div>

        <nav className="edit-nav">
          <hr className="dark" />

          <NavLink to={`/mascot/${mascot._id}/insights/overview`} className="edit-nav-item">
            <span>Overview</span>
          </NavLink>

          <NavLink to={`/mascot/${mascot._id}/insights/sessions`} className="edit-nav-item">
            <span>Session Analysis</span>
          </NavLink>

          <NavLink to={`/mascot/${mascot._id}/insights/interactions`} className="edit-nav-item">
            <span>Interaction Metrics</span>
          </NavLink>
        </nav>
      </div>

      <div className="mascot-sidebar-bg-wrapper">
        <div className="mascot-sidebar-bg" style={mascot.image && { backgroundImage: `url(${mascot.image})` }}></div>
      </div>
    </div>
  );
}
