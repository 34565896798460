import React, { useState } from "react";
import { useTasks } from "../hooks/useTasks";
import Loader from "./Loader";
import classNames from "classnames";
import { TaskFileIcon } from "../components/FileIcon";

import SuccessIcon from "../images/icons/status-success.svg";
import WarningIcon from "../images/icons/status-warning.svg";

export default function PendingTasksPopover() {
  const {
    taskList,
    embeddingTask,
    handleStopTask,
    embeddingTaskLastResult,
    minimized,
    setMinimized,
    embeddingProgress,
    setEmbeddingTaskLastResult,
  } = useTasks();
  const [filter, setFilter] = useState();
  const [filterTab, setFilterTab] = useState(1);
  const [expanded, setExpanded] = useState();

  const handleFilter = (i, filter) => {
    setFilter(filter);
    setFilterTab(i);
  };

  const getTitle = () => {
    let title = "";
    let pendingCount = taskList.filter((task) => task.status === "queued").length;
    if (pendingCount > 0) {
      title = "Preparing " + pendingCount + " file" + (pendingCount === 1 ? "" : "s");
    } else {
      let successCount = taskList.filter((task) => task.status === "completed").length;
      title = successCount + " file" + (successCount === 1 ? "" : "s") + " ready to publish";
    }
    return title;
  };

  return (
    <>
      <div
        className={classNames(
          "popover pending-tasks",
          taskList.length === 0 && !embeddingTask && "no-tasks",
          expanded && "expanded",
          minimized && "minimized",
          embeddingTask && "updating",
          embeddingTaskLastResult && "finished",
          embeddingTaskLastResult && embeddingTaskLastResult.error && "has-error"
        )}
      >
        <div className="popover-top-actions">
          {!expanded ? (
            !minimized ? (
              <>
                <div className="expand icon-btn" onClick={() => setExpanded(true)}>
                  <i className="icon-expand"></i>
                </div>

                <div className="minimize icon-btn" onClick={() => setMinimized(true)}>
                  <i className="icon-chevron-down"></i>
                </div>
              </>
            ) : (
              <div className="open icon-btn" onClick={() => setMinimized(false)}>
                <i className="icon-chevron-up"></i>
              </div>
            )
          ) : (
            <div className="retract icon-btn" onClick={() => setExpanded(false)}>
              <i className="icon-retract"></i>
            </div>
          )}
        </div>

        {embeddingTask ? (
          <>
            <h5>
              <Loader></Loader>
              Updating Training Index
            </h5>
            <p className="small">You cannot edit files until the update is finished or canceled.</p>

            <div className="update-progress">
              <div className="progress-bar">
                {embeddingProgress.progress >= 5 && (
                  <div className="progress-perc meta small" style={{ left: embeddingProgress.progress + "%" }}>
                    {embeddingProgress.progress + "%"}
                  </div>
                )}
                <progress value={embeddingProgress.progress} max={100}></progress>
              </div>

              <div className="file-count meta small">{embeddingProgress.task}</div>

              <button className="xsmall" onClick={() => handleStopTask(embeddingTask.id)}>
                Cancel Update
              </button>
            </div>
          </>
        ) : embeddingTaskLastResult ? (
          !embeddingTaskLastResult.error ? (
            <>
              <h5>
                <img src={SuccessIcon} alt={SuccessIcon} /> Mascot Ready
              </h5>
              <p className="small">We’ve sent you a confirmation email.</p>
            </>
          ) : (
            <>
              <h5>
                <img src={WarningIcon} alt={WarningIcon} /> Issue Updating Mascot
              </h5>
              <p className="small">{embeddingTaskLastResult.value}</p>
              <button className="small" onClick={() => setEmbeddingTaskLastResult(null)}>
                Back to files
              </button>
            </>
          )
        ) : (
          taskList.length > 0 && (
            <>
              <h5>{getTitle()}</h5>
              <p className="small">
                Note: You will need to publish your mascot when files are done processing. Files with errors will be omitted.
              </p>

              <div className="filter-bar">
                <button className={classNames("xxsmall", filterTab !== 1 && "outline")} onClick={() => handleFilter(1, "")}>
                  All
                </button>
                <button className={classNames("xxsmall", filterTab !== 2 && "outline")} onClick={() => handleFilter(2, "queued")}>
                  Processing
                </button>
                <button
                  className={classNames("xxsmall", filterTab !== 3 && "outline")}
                  onClick={() => handleFilter(3, "completed")}
                >
                  Completed
                </button>
                <button className={classNames("xxsmall", filterTab !== 4 && "outline")} onClick={() => handleFilter(4, "failed")}>
                  Errors
                </button>
              </div>

              <div className="list">
                <ul>
                  {taskList &&
                    taskList
                      .filter((t) => !filter || t.status === filter)
                      .map((task, index) => {
                        return (
                          <li className="list-item" key={index}>
                            <TaskFileIcon task={task} />

                            <div className="list-item-name">
                              <div className="list-item-file-name">{task.meta && (task.meta.url || task.meta.filename)}</div>
                              {(task.status === "failed" || task.status === "canceled") && (
                                <div className="meta small danger">{task.meta.error || task.error}</div>
                              )}
                            </div>
                            {task.status === "queued" && (
                              <div className="processing">
                                <Loader></Loader>
                                {task.type !== "uploadFileTask" && (
                                  <div className="cancel" onClick={() => handleStopTask(task.id)}>
                                    <i className="icon-close"></i>
                                  </div>
                                )}
                              </div>
                            )}
                            {(task.status === "failed" || task.status === "canceled") && (
                              <img className="status-icon" src={WarningIcon} alt={WarningIcon} />
                            )}
                            {task.status === "completed" && <img className="status-icon" src={SuccessIcon} alt={SuccessIcon} />}
                          </li>
                        );
                      })}
                </ul>
              </div>
            </>
          )
        )}
      </div>

      {expanded && <div className="pending-tasks-overlay"></div>}
    </>
  );
}
