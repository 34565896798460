import React from "react";
import classNames from "classnames";
import MascotImage from "./MascotImage";
import EditNav from "./EditNav";
import Loader from "./Loader";
import { useEditMascot } from "../hooks/useEditMascot";

export default function MascotSidebarEdit({ sidebarOpen, setSidebarOpen, loading }) {
  const { mascotDraft } = useEditMascot();

  return (
    <div className={classNames("mascot-sidebar", !mascotDraft.image && "bg-empty", sidebarOpen && "open")}>
      {/* Close button for mobile */}
      <div className="icon-btn close-sidebar" onClick={() => setSidebarOpen(!sidebarOpen)}>
        <i className="icon-close"></i>
      </div>

      <div className="mascot-sidebar-content">
        <div className="mascot-header">
          <div className="mascot-image-wrapper">
            {loading && (
              <div className="thinking">
                <Loader></Loader>
              </div>
            )}
            <MascotImage mascot={mascotDraft}></MascotImage>
          </div>

          {mascotDraft.name && (
            <div className="mascot-heading">
              <h2>{mascotDraft.name}</h2>
              <p>{mascotDraft.description}</p>
            </div>
          )}
        </div>
        <EditNav />
      </div>
      <div className="mascot-sidebar-bg-wrapper">
        <div
          className="mascot-sidebar-bg"
          style={
            mascotDraft.image && {
              backgroundImage: `url(${mascotDraft.image})`,
            }
          }
        ></div>
      </div>
    </div>
  );
}
