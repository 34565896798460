import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";

// Components
import MascotSidebarEdit from "../components/MascotSidebarEdit";
import PublishBar from "../components/PublishBar";

// Routes components
import EditMascotInfo from "./EditMascotInfo";
import EditMascotDataFile from "./EditMascotDataFile";
import EditMascotDataWeb from "./EditMascotDataWeb";
import EditMascotDataGoogleDrive from "./EditMascotDataGoogleDrive";
import EditMascotDataConfluence from "./EditMascotDataConfluence";
import EditMascotDataZendesk from "./EditMascotDataZendesk";
import EditMascotDataOneDrive from "./EditMascotDataOneDrive";
import EditMascotDataSharepoint from "./EditMascotDataSharepoint";
import EditMascotDocument from "./EditMascotDocument";
import EditMascotTheme from "./EditMascotTheme";
import EditMascotSettings from "./EditMascotSettings";
import EditMascotBehaviors from "./EditMascotBehaviors";
import EditMascotQr from "./EditMascotQr";
import EditMascotEmbed from "./EditMascotEmbed";
import { ProvideEditMascot } from "../hooks/useEditMascot";
import { ProvideCurrentEditors } from "../hooks/useCurrentEditors";

export default function EditMascotLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <ProvideEditMascot>
      <ProvideCurrentEditors>
        <div className="edit-mascot-wrapper">
          <PublishBar></PublishBar>

          <div className="mascot-wrapper">
            <MascotSidebarEdit editing sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}></MascotSidebarEdit>

            <div className="edit-mascot">
              <div className="icon-btn open-sidebar" onClick={() => setSidebarOpen(!sidebarOpen)}>
                <i className="icon-menu"></i>
              </div>

              {/* ROUTE EDIT */}
              <Switch>
                <Route path="/mascot/:mascotId/edit/data" exact>
                  <EditMascotDataFile />
                </Route>

                <Route path="/mascot/:mascotId/edit/data/web">
                  <EditMascotDataWeb />
                </Route>

                <Route path="/mascot/:mascotId/edit/data/google-drive">
                  <EditMascotDataGoogleDrive />
                </Route>

                <Route path="/mascot/:mascotId/edit/data/confluence">
                  <EditMascotDataConfluence />
                </Route>

                <Route path="/mascot/:mascotId/edit/data/zendesk">
                  <EditMascotDataZendesk />
                </Route>

                <Route path="/mascot/:mascotId/edit/data/one-drive">
                  <EditMascotDataOneDrive />
                </Route>

                <Route path="/mascot/:mascotId/edit/data/sharepoint">
                  <EditMascotDataSharepoint />
                </Route>

                <Route path="/mascot/:mascotId/edit/data/document/:documentId">
                  <EditMascotDocument />
                </Route>

                <Route path="/mascot/:mascotId/edit/theme">
                  <EditMascotTheme />
                </Route>

                <Route path="/mascot/:mascotId/edit/settings">
                  <EditMascotSettings />
                </Route>

                <Route path="/mascot/:mascotId/edit/behaviors">
                  <EditMascotBehaviors />
                </Route>

                <Route path="/mascot/:mascotId/edit/qr">
                  <EditMascotQr />
                </Route>

                <Route path="/mascot/:mascotId/edit/embed">
                  <EditMascotEmbed />
                </Route>

                <Route path="/mascot/:mascotId/edit/info">
                  <EditMascotInfo />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </ProvideCurrentEditors>
    </ProvideEditMascot>
  );
}
