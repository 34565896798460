import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "../components/Modal";
import { client } from "../services/api";
import classNames from "classnames";
import { useMascot } from "../hooks/useMascot";

export default function EditMascotEmbed() {
  const { mascotId } = useParams();
  const [domainsModalOpen, setDomainsModalOpen] = useState(false);
  const { mascot, setMascot } = useMascot();
  const [newDomain, setNewDomain] = useState("");
  const [tabNum, setTabNum] = useState(1);

  const iframeEmbed = `<div style="width: 100%; height: 768px;">
  <iframe
    id="wiseox-iframe"
    style="border: none; width: 100%; height: 100%"
    src="${process.env.REACT_APP_URL_APP}/mascot/${mascotId}"
  ></iframe>
</div>`;

  const dynamicPromptCode = `<script type="application/javascript">
  // Send message
  const sendMessage = () => {
    // Get iFrame ref
    let iframeElement = document.getElementById("wiseox-iframe");

    // Get message field value
    let messageValue = document.getElementById("messageField").value;

    const iframeWindow = iframeElement.contentWindow;

    // Send the event to the iframe.
    iframeWindow.postMessage(
      {
        type: "send-message",
        message: messageValue,
      },
      "*"
    );
  };

  // Receive Message
  window.addEventListener("message", (e) => {
    if (e.data.type == "received-message") {
      if (e.data.mascotId == "${mascotId}") {
        // Print the response
        let mascotResponse = document.getElementById("mascotResponse");
        mascotResponse.innerHTML = e.data.message;
      }
    }
  });
</script>

<div>
  <input type="text" id="messageField">
  <button onclick="sendMessage()">Submit</button>
</div>

<p id="mascotResponse"></p>`;

  const buttonEmbed = `<wiseox-chat
  mascot-id="${mascotId}"
  label="BUTTON_LABEL"
  label-color="#3DBFBF"
></wiseox-chat>

<script src="${process.env.REACT_APP_URL_APP}/static/js/embed-wiseox.js"></script>`;

  const generatebutton = `<wiseox-generate-button
  prompt="Generate a text snippet. Respond with only the value."
  label="Generate"
  custom-class="btn"
  response="FUNCTION"
></wiseox-generate-button>`;

  const receiveResponse = `<input type="text" id="FIELD_ID" />

<script type="application/javascript">
  window.FUNCTION = (e) => {
    document.getElementById("FIELD_ID").value = e;
  };
</script>`;

  const addDomain = () => {
    if (newDomain === "") return;
    if (!mascot.allowedDomains) {
      mascot.allowedDomains = [];
    }

    mascot.allowedDomains.push(newDomain);
    setNewDomain("");
    client.updateMascot({ _id: mascot._id, allowedDomains: mascot.allowedDomains });
  };

  const removeDomain = (i) => {
    mascot.allowedDomains.splice(i, 1);
    setMascot({ ...mascot });
    client.updateMascot({ _id: mascot._id, allowedDomains: mascot.allowedDomains });
  };

  return (
    <div>
      <div>
        <div className="edit-mascot-header" style={{ border: "none" }}>
          <h1>Install Mascot</h1>

          <button className="small outline" onClick={() => setDomainsModalOpen(true)}>
            Domain Safelist
          </button>
        </div>

        <div className="edit-mascot-tabbar">
          <div className={classNames("tabbar-item", tabNum === 1 && "active")} onClick={() => setTabNum(1)}>
            <i className="icon-inline-embed"></i>
            <span>Inline Embed</span>
          </div>
          <div className={classNames("tabbar-item", tabNum === 2 && "active")} onClick={() => setTabNum(2)}>
            <i className="icon-button-embed"></i>
            <span>Button Embed</span>
          </div>
        </div>

        {tabNum === 1 && (
          <>
            <div className="content-wrapper full-width col-2">
              <div className="content-col">
                <h3>
                  <i className="icon-inline-embed"></i> Inline Embed
                </h3>
                <p>
                  You can embed your mascot in another web application using the iFrame code. The mascot will appear within your
                  content.
                </p>
                <p>
                  We also recommend adding your domain to the Safelist to avoid malicious users from hijacking your mascot. Use
                  the "Domain Safelist" in the upper right.
                </p>
                <p className="meta warning">Note: You will need to enable "Public Mascot" in the Edit Mascot tab.</p>
              </div>

              <div className="content-col">
                <div className="preview-window code-snippet">
                  <div className="preview-content">
                    <span className="meta small">
                      Copy and paste this code inline with your HTML on any page you want to enable mascot chat.
                    </span>
                    <pre className="code-snippet-custom">{iframeEmbed}</pre>
                    <button
                      className="small"
                      onClick={() => {
                        toast.promise(navigator.clipboard.writeText(iframeEmbed), {
                          success: "Copied to clipboard",
                          error: "Error copying to clipboard",
                        });
                      }}
                    >
                      Copy Code
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="content-wrapper full-width col-2">
              <div className="content-col">
                <h5>Advanced: Send and Receive Messages</h5>
                <p>
                  When using Inline Embed you can send messages from your application to the mascot, and receive responses, from
                  outside of the iFrame.
                </p>
                <p>
                  This can be accomplished by using the <code>postMessage</code> parameter <code>send-message</code>. Responses
                  can be received using an <code>eventListener</code> on the iframe window for <code>received-messages</code>.
                </p>
                <p>Use this code example, and modify accordingly.</p>
              </div>

              <div className="content-col">
                <div className="preview-window code-snippet">
                  <div className="preview-content">
                    <pre className="code-snippet-custom">{dynamicPromptCode}</pre>
                    <button
                      className="small"
                      onClick={() => {
                        toast.promise(navigator.clipboard.writeText(dynamicPromptCode), {
                          success: "Copied to clipboard",
                          error: "Error copying to clipboard",
                        });
                      }}
                    >
                      Copy Code
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {tabNum === 2 && (
          <>
            <div className="content-wrapper full-width col-2">
              <div className="content-col">
                <h3>
                  <i className="icon-button-embed"></i> Button Embed
                </h3>
                <p>
                  Your mascot can be embedded like a conventional chat bot in the lower right corner of your site. When the mascot
                  avatar is clicked, the chat window will open.
                </p>
                <p>
                  We also recommend adding your domain to the Safelist to avoid malicious users from hijacking your mascot. Use
                  the "Domain Safelist" in the upper right.
                </p>
                <p className="meta warning">Note: You will need to enable "Public Mascot" in the Edit Mascot tab.</p>
              </div>

              <div className="content-col">
                <div className="preview-window code-snippet">
                  <div className="preview-content">
                    <span className="meta small">
                      Copy and paste this code inline with your HTML on any page you want to enable mascot chat.
                    </span>
                    <pre className="code-snippet-custom">{buttonEmbed}</pre>
                    <button
                      className="small"
                      onClick={() => {
                        toast.promise(navigator.clipboard.writeText(buttonEmbed), {
                          success: "Copied to clipboard",
                          error: "Error copying to clipboard",
                        });
                      }}
                    >
                      Copy Code
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="content-wrapper full-width col-2">
              <div className="content-col">
                <h5>Advanced: Generate Text</h5>
                <p>
                  When using Button Embed you can prompt the mascot from a separate button on your website outside of the chat.
                </p>
                <p>
                  To do this, include the <code>{"<wiseox-generate-button>"}</code> component and set the specific prompt you want
                  the mascot to follow. You can also set the button label and a custom-class to target the button for specific
                  styles.
                </p>
                <p>
                  <b>Capture the reply</b>
                </p>
                <p>
                  If desired, you can have the mascot respond with the text outside of the chat in your website. This can be used
                  to prefill text fields, or produce text on the page generated from the mascot.
                </p>
                <p>
                  To do this, copy the <code>{"<script>"}</code> in the example on the right and paste it ABOVE the{" "}
                  <code>{"<wiseox-generate-button>"}</code> component.
                </p>
                <p>
                  You will need to define a response <code>FUNCTION</code> and <code>FIELD_ID.</code>
                </p>
              </div>

              <div className="content-col">
                <div className="preview-window code-snippet">
                  <div className="preview-content">
                    <span className="meta small">
                      Copy and paste this code wherever you want to include a “Generate” button in your HTML. <code>Prompt</code>{" "}
                      and <code>label</code> properties are required.
                    </span>
                    <pre className="code-snippet-custom">{generatebutton}</pre>
                    <button
                      className="small"
                      onClick={() => {
                        toast.promise(navigator.clipboard.writeText(generatebutton), {
                          success: "Copied to clipboard",
                          error: "Error copying to clipboard",
                        });
                      }}
                    >
                      Copy Code
                    </button>
                  </div>
                </div>

                <div className="preview-window code-snippet">
                  <div className="preview-content">
                    <pre className="code-snippet-custom">{receiveResponse}</pre>
                    <button
                      className="small"
                      onClick={() => {
                        toast.promise(navigator.clipboard.writeText(receiveResponse), {
                          success: "Copied to clipboard",
                          error: "Error copying to clipboard",
                        });
                      }}
                    >
                      Copy Code
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Modal title="Domain Safelist" size="small" isOpen={domainsModalOpen} close={() => setDomainsModalOpen(false)}>
        <p>Add the domain(s) where this mascot will live so malicious users cannot hijack it for their own use.</p>
        <p>If no domain is specified, the mascot can be embedded anywhere.</p>
        <p className="warning meta">Note: Do not include "https" in your domain.</p>

        <div className="input-group">
          <label>Enter Domains</label>
          <div className="input-combo over">
            <input
              className="small"
              type="text"
              value={newDomain}
              placeholder="e.g. mydomain.com"
              onChange={(e) => setNewDomain(e.target.value)}
            ></input>
            <button className="small" onClick={addDomain}>
              Add
            </button>
          </div>
        </div>

        {mascot.allowedDomains && mascot.allowedDomains.length > 0 && (
          <>
            <span className="meta">Domains Added</span>
            <div className="list">
              <ul>
                {mascot.allowedDomains &&
                  mascot.allowedDomains.map((d, index) => (
                    <li className="list-item clickable small solid" key={index}>
                      <div className="list-item-name">{d}</div>
                      <div className="list-item-actions">
                        <i className="icon-close" onClick={() => removeDomain(index)}></i>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}
