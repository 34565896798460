import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";

// Libs
import { useHistory, useParams } from "react-router-dom";
import { client } from "../services/api.js";

// Components
import MascotSidebarChat from "../components/MascotSidebarChat.js";
import MascotImage from "../components/MascotImage.js";
import Loader from "../components/Loader.js";
import MessageChat from "../components/MessageChat.js";

export default function MascotChatReadOnly() {
  const { shareToken } = useParams();
  const [loadingConversation, setLoadingConversation] = useState(true);
  const [mascot, setMascot] = useState({});
  const [session, setSession] = useState({});
  const [messages, setMessages] = useState([]);
  const [unavailable, setUnavailable] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const chatEndEl = useRef();
  const chatWrapperEl = useRef();
  const history = useHistory();

  useEffect(() => {
    client.getConversationByShareToken(shareToken).then((result) => {
      if (!result.ok) {
        setLoadingConversation(false);
        setUnavailable(true);
      }
      if (result.ok) {
        if (result.data.ownMascot) {
          return history.push("/mascot/" + result.data.mascot._id + "/chat/" + result.data._id);
        }

        setMascot(result.data.mascot);
        setLoadingConversation(false);
        setSession(result.data);
        setMessages(result.data?.messages);
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="mascot-wrapper chat hide-bobbie">
      {!unavailable && (
        <MascotSidebarChat
          sidebarOpen={sidebarOpen}
          showBadge={true}
          mascotInit={mascot}
          setSidebarOpen={setSidebarOpen}
          loading={loadingConversation}
        />
      )}

      <div className={classNames("mascot-chat-panel", mascot.theme && mascot.theme.darkTheme && "dark-theme")}>
        {/* Load Libs */}
        <link rel="preconnect" href="https://fonts.gstatic.com" />

        {mascot.theme && mascot.theme.fontFamily && (
          <link
            rel="stylesheet"
            href={`https://fonts.googleapis.com/css2?family=${mascot.theme.fontFamily.replace(
              /\s/g,
              "+"
            )}:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap`}
          />
        )}

        {unavailable ? (
          <div className="not-ready">
            {/* Unavailable state */}
            <h2>{"This session is unavailable"}</h2>
          </div>
        ) : (
          <>
            {/* Header chat */}

            <div className="chat-header">
              {/* Open Sidebar for Mobile */}
              <div className="chat-header-left">
                <div className="icon-btn open-sidebar" onClick={() => setSidebarOpen(!sidebarOpen)}>
                  <i className="icon-menu"></i>
                </div>
              </div>

              <div className="chat-header-middle">
                <div className="mobile-mascot-header">
                  <MascotImage mascot={mascot}></MascotImage>

                  {mascot.name && <h2>{mascot.name}</h2>}
                </div>

                <div className="chat-title">
                  <h6>{session.title}</h6>
                </div>
              </div>
            </div>

            {/* Chat container */}
            <div className="chat-wrapper" style={{ fontFamily: mascot.theme && mascot.theme.fontFamily }}>
              <div className="messages-wrapper" ref={chatWrapperEl}>
                {loadingConversation ? (
                  <p className="empty-message">
                    <Loader></Loader>
                  </p>
                ) : (
                  <>
                    {messages.length === 0 ? (
                      <p className="empty-message">Get started by asking {mascot.name} anything.</p>
                    ) : (
                      <div className="messages">
                        {messages.map((message, i) => {
                          return <MessageChat message={message} mascot={mascot} key={i}></MessageChat>;
                        })}

                        <div ref={chatEndEl}></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
