import React from "react";
import { useAuth } from "../hooks/useAuth";
import classNames from "classnames";

export default function FileInput({ inputRef, onChange, label, className, multiple = false, disabled = false, formats }) {
  const { uploadInfo } = useAuth();

  return (
    <div className="file-input">
      <label htmlFor="uploadButton" disabled={disabled} className={classNames('button', className)}>
        {label}
      </label>
      <input
        ref={inputRef}
        onChange={onChange}
        id="uploadButton"
        type="file"
        multiple={multiple}
        disabled={disabled}
        {...(uploadInfo.supportedFormats && uploadInfo.supportedFormats.length > 0
          ? { accept: formats ? formats.join(",") : uploadInfo.supportedFormats.join(",") }
          : {})}
      />
    </div>
  );
}
