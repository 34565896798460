import React, { useState, useEffect, useCallback } from "react";
import Modal from "./Modal";
import Loader from "./Loader";
import { useAuth } from "../hooks/useAuth";
import { servicesClient } from "../services/servicesApi";
import TreeViewFilePicker from "./TreeViewFilePicker";
// import classNames from "classnames";
import { FileIcon } from "../components/FileIcon";

function ZendeskFilePicker({ isShown, setShown, onSelect, subdomain }) {
  const { currentOrganization } = useAuth();
  const [selectedIds, setSelectedIds] = useState([]);
  const [exportedSelectedIds, setExportedSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  // const [filterTab, setFilterTab] = useState(1);

  const initialData = [
    {
      name: subdomain,
      id: 0,
      children: [1, 2],
      parent: null,
    },
    {
      name: "Articles",
      id: 1,
      children: [],
      parent: 0,
      isBranch: true,
      type: "folder",
    },
    {
      name: "Posts",
      id: 2,
      children: [],
      parent: 0,
      isBranch: true,
      type: "folder",
    },
  ];

  const [data, setData] = useState(initialData);
  const [filteredData, setFilteredData] = useState(initialData);

  useEffect(() => {
    if (isShown) {
      setLoading(true);
      const sectionsPromise = servicesClient.getZendeskSections(currentOrganization._id, 0).then((result) => {
        result.ok && setData((value) => updateTreeCategories(value, result.data));
      });
      const postsPromise = servicesClient.getZendeskPosts(currentOrganization._id, 0).then((result) => {
        result.ok && setData((value) => updateTreePosts(value, result.data));
      });
      Promise.all([sectionsPromise, postsPromise]).then(() => setLoading(false));
    }
  }, [isShown, currentOrganization]);

  const updateTreeCategories = (list, data) => {
    for (let c of data.categories.categories) {
      let idCategory = list.length;
      if (!list.find((node) => node.idCategory === c.id)) {
        list.push({
          name: c.name,
          id: idCategory,
          children: [],
          parent: 1,
          isBranch: false,
          type: "category",
          idCategory: c.id,
        });

        list[1].children.push(idCategory);
      }

      for (let s of data.sections.sections) {
        if (s.category_id === c.id) {
          if (!list.find((node) => node.sectionId === s.id)) {
            let idSection = list.length;
            list.push({
              name: s.name,
              id: idSection,
              children: [],
              parent: idCategory,
              isBranch: true,
              type: "section",
              sectionId: s.id,
            });

            list[idCategory].children.push(idSection);
          }
        }
      }
    }

    return [...list];
  };

  const updateTreePosts = (list, posts) => {
    for (let p of posts.posts) {
      if (!list.find((node) => node.postId === p.id)) {
        let id = list.length;
        list.push({
          name: p.title,
          id: id,
          children: [],
          parent: 2,
          isBranch: false,
          type: "post",
          content: p.details,
          postId: p.id,
          selectable: true,
        });

        list[2].children.push(id);
      }
    }

    return [...list];
  };

  const updateTreeArticles = (list, parentNode, articles) => {
    for (let a of articles.articles) {
      if (!list.find((node) => node.articleId === a.id)) {
        let id = list.length;
        list.push({
          name: a.title,
          id: id,
          children: [],
          parent: parentNode,
          isBranch: true,
          type: "article",
          content: a.content,
          articleId: a.id,
          selectable: true,
        });

        list[parentNode].children.push(id);
      }
    }

    list[parentNode].isBranch = false;

    return [...list];
  };

  const updateTreeArticlesAttachments = (list, parentNode, attachments) => {
    for (let a of attachments.article_attachments) {
      if (!list.find((node) => node.attachmentId === a.id)) {
        let id = list.length;
        list.push({
          name: a.display_file_name,
          id: id,
          children: [],
          parent: parentNode,
          isBranch: false,
          type: "attachment",
          attachmentId: a.id,
          selectable: true,
        });

        list[parentNode].children.push(id);
      }
    }

    list[parentNode].isBranch = false;

    return [...list];
  };

  const loadData = useCallback(
    async ({ element }) => {
      if (element.type === "section" && element.isBranch) {
        const result = await servicesClient.getZendeskArticles(currentOrganization._id, element.sectionId);
        result.ok && setData((value) => updateTreeArticles(value, element.id, result.data));
      }
      if (element.type === "article" && element.isBranch) {
        const result = await servicesClient.getZendeskArticlesAttachments(currentOrganization._id, element.articleId);
        result.ok && setData((value) => updateTreeArticlesAttachments(value, element.id, result.data));
      }
    },
    [currentOrganization]
  );

  const selectAllIds = () => {
    setSelectedIds(data.map((i) => i.id));
  };

  const _onSelect = useCallback(
    ({ treeState }) => {
      setExportedSelectedIds(
        data
          .filter((d) => treeState.selectedIds.has(d.id))
          .map((d) => ({ id: d.postId || d.articleId || d.attachmentId, name: d.name, type: d.type }))
      );
    },
    [data]
  );

  const filterTree = useCallback(
    (value, filerByType = false) => {
      const filtered = [];
      data.forEach((item) => {
        if (item.id === 0) {
          return;
        }
        let check = false;
        if (filerByType) {
          check = item.type === value;
        } else {
          check = item.name.includes(value.toUpperCase());
        }
        if (check) {
          if (!filtered.find((x) => x.id === item.id)) {
            filtered.push(item);
          }
        }
      });
      filtered.unshift(
        Object.assign({
          ...data[0],
          children: data[0].children.filter((id) => filtered.find((fitem) => fitem.id === id)),
        })
      );
      setData(filtered);
    },
    [data]
  );

  useEffect(() => {
    if (!!filter) {
      filterTree(filter);
    } else {
      setData(data);
    }
  }, [filter, data, filterTree, setData]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <Modal
      title="Zendesk Content"
      isOpen={!!isShown}
      close={() => setShown(false)}
      size="large"
      className="picker-modal"
      action={
        <>
          <button
            className="action"
            onClick={() => {
              onSelect(exportedSelectedIds);
              setShown(false);
            }}
          >
            Select Content
          </button>
          <button onClick={() => setShown(false)}>Cancel</button>
        </>
      }
    >
      <input
        type="text"
        className="small picker-search"
        placeholder="Search Zendesk"
        value={filter}
        onChange={(event) => setFilter(event.target.value)}
      />

      {/* Aug 19th - Filters are buggy, hiding for now */}

      {/* <div className="filter-bar">
        <button
          className={classNames("xxsmall", filterTab !== 1 && "outline")}
          onClick={() => {
            setFilterTab(1);
            filterTree('');
          }}
        >
          All
        </button>
        <button
          className={classNames("xxsmall", filterTab !== 3 && "outline")}
          onClick={() => {
            setFilterTab(2);
            filterTree("category", true);
          }}
        >
          Categories
        </button>
        <button
          className={classNames("xxsmall", filterTab !== 4 && "outline")}
          onClick={() => {
            setFilterTab(3);
            filterTree("post", true);
          }}
        >
          Posts
        </button>
        <button
          className={classNames("xxsmall", filterTab !== 5 && "outline")}
          onClick={() => {
            setFilterTab(4);
            filterTree("section", true);
          }}
        >
          Sections
        </button>
        <button
          className={classNames("xxsmall", filterTab !== 5 && "outline")}
          onClick={() => {
            setFilterTab(5);
            filterTree("article", true);
          }}
        >
          Articles
        </button>
      </div> */}

      <div className="select-all">
        <span className="meta" onClick={() => selectAllIds()}>
          Select All
        </span>
        <span className="meta" onClick={() => setSelectedIds([])}>
          Deselect All
        </span>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <TreeViewFilePicker
          data={filteredData}
          disableCheck={true}
          loadData={loadData}
          selectedIds={selectedIds}
          onSelect={_onSelect}
          renderItem={(element) => <span className="name"><FileIcon doc={element} type='zendesk' /> {element.name}</span>}
        />
      )}
    </Modal>
  );
}

export default ZendeskFilePicker;
