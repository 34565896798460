import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { client } from "../services/api";

export default function MascotExcerpts({ message, setShowExcerpts }) {
  const [isOpen, setIsOpen] = useState({});
  const [sourceDocuments, setSourceDocuments] = useState();

  useEffect(() => {
    const fetchData = async () => {
      let res = await client.getSourceDocuments(message._id);
      if (res.ok) {
        setSourceDocuments(res.data);
      }
    };

    if (message && message._id) {
      fetchData();
    }
  }, [message]);

  const getData = (doc) => {
    return doc.node.replace("[Excerpt from document]\n\nExcerpt:\n-----\n", "").replace("\n-----", "");
  };
  const getTitle = (doc) => {
    if (doc.metadata.original_file_name && doc.metadata.original_file_name.indexOf("http") === 0) {
      return decodeURIComponent(doc.metadata.original_file_name).replace(".txt", "");
    } else {
      return doc.metadata.original_file_name || doc.metadata.parsed_file_name?.slice(0, -4) || "";
    }
  };

  return message ? (
    <div className="drawer mascot-excerpts">
      <div className="icon-btn" onClick={() => setShowExcerpts(false)}>
        <i className="icon-close"></i>
      </div>

      <h5>Response References</h5>

      <p className="small">
        These are relevant excerpts from the training data to help the mascot with providing an answer. They are provided
        regardless if the mascot gives a correct answer or not.
      </p>

      {sourceDocuments && sourceDocuments.length === 0 && <span className="meta">No references found</span>}
      {sourceDocuments?.map((doc, k) => (
        <div key={k} className="excerpt">
          <span className="meta">Excerpt {k + 1}</span>

          <div
            className="excerpt-title"
            onClick={() => {
              isOpen[k] = true;
              setIsOpen({ ...isOpen });
            }}
          >
            <p className="small">{getTitle(doc)}</p>
          </div>

          <Modal
            title={getTitle(doc)}
            size="large"
            isOpen={isOpen[k]}
            close={() => {
              isOpen[k] = false;
              setIsOpen({ ...isOpen });
            }}
          >
            <blockquote>{getData(doc)}</blockquote>
          </Modal>
        </div>
      ))}
    </div>
  ) : null;
}
