import React, { useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../hooks/useAuth";
import useQuery from '../hooks/useQuery';

import BgImg from "../images/sign-in-bg.png";
import Logo from "../images/logo-wiseox.svg";

const schema = Yup.object().shape({
  newPassword: Yup.string()
    .required("Password required")
    .min(8, "Password must be 8 chars minimum")
    .matches(/(?=.*[A-Z])/, "Password must contain one uppercase character")
    .matches(/(?=.*[a-z])/, "Password must contain one lowercase character")
    .matches(/(?=.*[0-9])/, "Password must contain one number")
    .matches(/^\S+$/, "No spaces allowed")
    .matches(/(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/, "Password must contain one special character"),
});

export function ResetPassword() {
  const [submitting, setSubmitting] = useState(false);
  const [isPasswordRest, setIsPasswordReset] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [failed, setFailed] = useState(null);
  const queryParams = useQuery();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const { confirmPasswordReset } = useAuth();

  const onSubmit = async (data) => {
    setSubmitting(true);
    try {
      const res = await confirmPasswordReset({
        token: queryParams.get("token"),
        password: data.newPassword,
      });
      if (!res.ok) {
        throw res.data;
      }
      setSubmitting(false);
      setIsPasswordReset(true);
    } catch (error) {
      setFailed(error);
      setSubmitting(false);
    }
  };

  function togglePasswordVisisble() {
    setPasswordVisible(!passwordVisible);
  }

  return (
    <div className="auth-wrapper" style={{ backgroundImage: `url(${BgImg})` }}>
      <div className="logo-wrapper">
        <img className="logo" src={Logo} alt="WiseOx Logo" />

        <NavLink to="/login">
          <i className="icon-chevron-left"></i> Sign In
        </NavLink>
      </div>

      <div className="signup-form">
        {isPasswordRest ? (
          <>
            <h1>Success!</h1>
            <p className="large">Your new password has been set.</p>
            <NavLink className="button" to="/signin">
              Back to Sign In
            </NavLink>
          </>
        ) : (
          <>
            <h1>Set Your Password</h1>

            <div className="input-group">
              <label className="hidden">New Password</label>

              <div className="input-combo over">
                <input
                  {...register("newPassword", { required: true })}
                  type={!passwordVisible ? "password" : "text"}
                  name="newPassword"
                  required
                  placeholder="Password"
                  tabIndex="1"
                />

                <div className="icon-btn" onClick={togglePasswordVisisble}>
                  {!passwordVisible ? <i className="icon-eye-off"></i> : <i className="icon-eye"></i>}
                </div>
              </div>

              {errors.newPassword && <div className="error">{errors.newPassword.message}</div>}
            </div>

            {failed && <div className="error">{failed.message}</div>}

            <button className="large positive" disabled={submitting} onClick={handleSubmit(onSubmit)} tabIndex="5">
              Set Password
            </button>
          </>
        )}
      </div>
    </div>
  );
}
