import React, { useEffect, useState } from "react";
import { useConversations } from "../hooks/useConversations";

const SelectTextPopup = ({ containerClass, onAction }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedText, setSelectedText] = useState();
  // eslint-disable-next-line
  const [selectedMessage, setSelectedMessage] = useState();
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const { mascot } = useConversations();

  const inlineActions = [
    {
      label: "Tell me more",
      prompt: "Provide me more information on “_TEXT_CHUNK_”",
      enabled: mascot.prompt_settings && mascot.prompt_settings.action_info_enable,
    },
    {
      label: "Quiz me",
      prompt: "Quiz me on “_TEXT_CHUNK_”",
      enabled: mascot.prompt_settings && mascot.prompt_settings.action_quiz_enable,
    },
    {
      label: "Be creative",
      prompt: "Be creative about “_TEXT_CHUNK_”",
      enabled: mascot.prompt_settings && mascot.prompt_settings.action_creative_enable,
    },
  ];

  const executeAction = (action) => {
    let prompt = action.prompt.replaceAll("_TEXT_CHUNK_", selectedText);
    onAction(prompt);
    setShowMenu(false);
    setSelectedMessage((selectedMessage) => {
      if (selectedMessage) selectedMessage.classList.remove("not-hoverable");
    });
  };

  useEffect(() => {
    const handleSelection = () => {
      const selection = window.getSelection();
      var menu = document.querySelector(".inline-actions");

      if (menu && menu.contains(selection.anchorNode)) {
        return;
      }

      let selectedNode = selection.anchorNode;
      if (selectedNode && !selection.anchorNode.closest) {
        selectedNode = selection.anchorNode.parentElement;
      }

      if (selection && selection.toString() && selectedNode.closest(containerClass)) {
        setSelectedMessage(selectedNode.closest(containerClass));
        const range = selection.getRangeAt(0).getBoundingClientRect();
        setMenuPosition({
          x: range.x,
          y: range.y + range.height,
        });
        setSelectedText(selection.toString());

        setShowMenu(true);
      } else {
        setShowMenu(false);
        setSelectedMessage((selectedMessage) => {
          if (selectedMessage) selectedMessage.classList.remove("not-hoverable");
        });
      }
    };

    document.addEventListener("mouseup", handleSelection);

    return () => {
      document.removeEventListener("mouseup", handleSelection);
    };
  }, [containerClass]);

  return (
    <div>
      {showMenu && (
        <div
          className="popover inline-actions"
          style={{
            left: menuPosition.x,
            top: menuPosition.y,
          }}
        >
          {inlineActions.map(
            (action, k) =>
              action.enabled && (
                <div className="popover-item" key={k} onClick={() => executeAction(action)}>
                  {action.label}
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default SelectTextPopup;
