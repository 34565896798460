import React from "react";

import IconDocument from "../images/icons/document.svg";
import IconWebsite from "../images/icons/globe.svg";
import IconPDF from "../images/icons/pdf.svg";
import IconImage from "../images/icons/image.svg";
import IconVideo from "../images/icons/video.svg";
import IconAudio from "../images/icons/audio.svg";
import IconMSWord from "../images/icons/ms-word.svg";
import IconMSExcel from "../images/icons/ms-excel.svg";
import IconMSPowerpoint from "../images/icons/ms-powerpoint.svg";
import IconMSOneNote from "../images/icons/ms-onenote.svg";
import IconConfluenceBlog from "../images/icons/confluence-blog.svg";
import IconConfluenceFile from "../images/icons/confluence-file.svg";
import IconConfluencePage from "../images/icons/confluence-page.svg";
import IconConfluenceSpace from "../images/icons/confluence-space.svg";
import IconGoogleDoc from "../images/icons/google-doc.svg";
import IconGoogleSheet from "../images/icons/google-sheet.svg";
import IconGoogleSlide from "../images/icons/google-slide.svg";
import IconGoogleDrive from "../images/icons/google-drive.svg";
import IconZendesk from "../images/icons/zendesk.svg";
import IconZendeskArticle from "../images/icons/zendesk-article.svg";
import IconZendeskAttachment from "../images/icons/zendesk-attachment.svg";
import IconZendeskPost from "../images/icons/zendesk-post.svg";

export const TaskFileIcon = ({ task }) => {
  task.meta = task.meta || {};
  const taskTypeMapping = {
    addFileTask: { name: task.meta.filename, type: "file" },
    addWebTask: { name: "website", type: "web" },
    refreshWebTask: { name: "website", type: "web" },
    addGoogleDriveTask: { name: task.meta.filename, mimeType: task.meta.mimeType, type: "gdrive" },
    refreshGoogleDriveTask: { name: task.meta.filename, mimeType: task.meta.mimeType, type: "gdrive" },
    addConfluenceTask: { id: task.meta.fileId, mimeType: task.meta.mimeType, type: "confluence" },
    refreshConfluenceTask: { id: task.meta.fileId, type: "confluence" },
    addOneDriveTask: { name: task.meta.filename, type: "onedrive" },
    refreshOneDriveTask: { name: task.meta.filename, type: "onedrive" },
    addSharepointTask: { name: task.meta.filename, type: "sharepoint" },
    addSharepointPageTask: { name: task.meta.filename, type: "sharepoint" },
    refreshSharepointTask: { name: task.meta.filename, type: "sharepoint" },
    addZendeskTask: { name: task.meta.filename, nodeType: task.meta.nodeType, type: "zendesk" },
    refreshZendeskTask: { name: task.meta.filename, nodeType: task.meta.nodeType, type: "zendesk" },
  };

  return <FileIcon doc={taskTypeMapping[task.type] || {}} />;
};

const getFileTypeIcon = (type, extension) => {
  const imageExtensions = ["png", "jpeg", "jpg", "tif", "tiff", "jp2", "gif", "webp", "bmp"];
  const audioExtensions = ["mp3", "mpga", "m4a", "wav", "webm"];
  const videoExtensions = ["mp4", "ogv", "mpeg", "avi", "mov"];

  if (type === "file") {
    if (imageExtensions.includes(extension)) return IconImage;
    if (audioExtensions.includes(extension)) return IconAudio;
    if (videoExtensions.includes(extension)) return IconVideo;
    if (extension === "pdf") return IconPDF;
    if (["xls", "xlsx"].includes(extension)) return IconMSExcel;
    if (["doc", "docx"].includes(extension)) return IconMSWord;
    if (["ppt", "pptx", "pptm"].includes(extension)) return IconMSPowerpoint;
    if (extension === "one") return IconMSOneNote;
  } else if (type === "web") {
    return IconWebsite;
  }
  return IconDocument;
};

export const FileIcon = ({ doc, type }) => {
  if (!type) {
    type = doc.url ? "web" : doc.type;
  }
  let name = doc.name;
  const extension = doc.name?.split(".").pop();
  let iconUrl = getFileTypeIcon(type, extension);

  if (type === "gdrive") {
    const { icon, _name } = getGoogleDriveFileIcon(doc);
    iconUrl = icon;
    name = _name;
  } else if (type === "confluence") {
    const { icon, _name } = getConfluenceFileIcon({ ...doc, id: doc.id || `${doc.nodeType}-${doc.confluenceId}` });
    iconUrl = icon;
    name = _name;
  } else if (type === "onedrive" || type === "sharepoint") {
    if (doc.type === "web") {
      iconUrl = IconWebsite;
    } else {
      return <FileIcon doc={{ ...doc, type: "file" }} />;
    }
  } else if (type === "zendesk") {
    const { icon, _name } = getZendeskFileIcon(doc);
    iconUrl = icon;
    name = _name;
  }

  return <img alt={name} className="list-item-icon" src={iconUrl} />;
};

const getGoogleDriveFileIcon = (doc) => {
  let icon = IconGoogleDrive;
  let name = doc.mimeType;

  if (doc.mimeType === "application/vnd.google-apps.document") {
    icon = IconGoogleDoc;
  } else if (doc.mimeType === "application/vnd.google-apps.spreadsheet") {
    icon = IconGoogleSheet;
  } else if (doc.mimeType === "application/vnd.google-apps.presentation") {
    icon = IconGoogleSlide;
  } else {
    icon = getFileTypeIcon("file", doc.name?.split(".").pop());
  }

  return { icon, name };
};

const getConfluenceFileIcon = (doc) => {
  const type = doc.id.split("-")[0];
  let icon = IconConfluenceFile;
  let name = "attachment";

  if (type === "s") {
    icon = IconConfluenceSpace;
    name = "space";
  } else if (type === "p") {
    icon = IconConfluencePage;
    name = "page";
  } else if (type === "b") {
    icon = IconConfluenceBlog;
    name = "blog";
  }

  return { icon, name };
};

const getZendeskFileIcon = (doc) => {
  let icon = IconDocument;
  let type = doc.nodeType || doc.type;

  if (type === "folder") {
    icon = IconZendesk;
  } else if (type === "category") {
    icon = IconZendesk;
  } else if (type === "post") {
    icon = IconZendeskPost;
  } else if (type === "section") {
    icon = IconZendesk;
  } else if (type === "article") {
    icon = IconZendeskArticle;
  } else if (type === "attachment") {
    icon = IconZendeskAttachment;
  }

  return { icon, name: type };
};
