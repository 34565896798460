import React, { useState, useRef } from "react";
import { NavLink, useParams, useHistory } from "react-router-dom";
import Modal from "../components/Modal";
import { client } from "../services/api";
import { useAuth } from "../hooks/useAuth";
import { useCurrentEditors } from "../hooks/useCurrentEditors";

export default function EditNav() {
  const { mascotId } = useParams();
  const { currentUser } = useAuth();
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [disabledText, setDisabled] = useState("");
  const archiveInputRef = useRef();
  const history = useHistory();
  const { currentEditors } = useCurrentEditors();

  const archiveMascot = async () => {
    if (archiveInputRef.current?.value === "archive") {
      await client.archiveMascot(mascotId);
      history.push("/");
    }
  };

  const handleEditors = (path) => {
    if (Object.values(currentEditors).filter(e => e.userId !== currentUser._id).some(editor => editor.path.endsWith(path))) {
      return (
        <div className="editors">
          {Object.values(currentEditors)
            .filter((editor) => editor.userId !== currentUser._id && editor.path.endsWith(path))
            .map((editor, i) => (
              <div
                className="editor small"
                key={i}
                style={{ backgroundColor: `#${editor.color}` }}
                title={editor.firstName + " " + editor.lastName}
              >
                <span>{editor.firstName.charAt(0) + editor.lastName.charAt(0)}</span>
              </div>
            ))}
        </div>
      );
    }
    return null;
  };
  

  return (
    <>
      <nav className="edit-nav">
        <hr className="dark" />

        <NavLink to={`/mascot/${mascotId}/edit/info`} className="edit-nav-item">
          <i className="icon-mascot"></i>
          <span>Persona</span>

          {handleEditors("info")}
        </NavLink>

        <NavLink to={`/mascot/${mascotId}/edit/data`} className="edit-nav-item">
          <i className="icon-data"></i>
          <span>Training Data</span>

          {handleEditors("data")}
        </NavLink>

        <NavLink to={`/mascot/${mascotId}/edit/behaviors`} className="edit-nav-item">
          <i className="icon-behavior"></i>
          <span>Behaviors</span>

          {handleEditors("behaviors")}
        </NavLink>

        <NavLink to={`/mascot/${mascotId}/edit/settings`} className="edit-nav-item">
          <i className="icon-settings"></i>
          <span>Settings</span>

          {handleEditors("settings")}
        </NavLink>

        <NavLink to={`/mascot/${mascotId}/edit/theme`} className="edit-nav-item">
          <i className="icon-wand"></i>
          <span>Theme</span>

          {handleEditors("theme")}
        </NavLink>

        <hr className="dark" />

        <NavLink to={`/mascot/${mascotId}/edit/embed`} className="edit-nav-item">
          <i className="icon-embed"></i>
          <span>Install Mascot</span>

          {handleEditors("embed")}
        </NavLink>

        <NavLink to={`/mascot/${mascotId}/edit/qr`} className="edit-nav-item">
          <i className="icon-qr"></i>
          <span>QR Code</span>
        </NavLink>

        <hr className="dark" />

        <div onClick={() => setArchiveModalOpen(true)} className="edit-nav-item">
          <i className="icon-archive"></i>
          <span>Archive Mascot</span>
        </div>
      </nav>

      <Modal
        title="Archive Mascot"
        size="small"
        isOpen={archiveModalOpen}
        close={() => setArchiveModalOpen(false)}
        action={
          <button onClick={archiveMascot} className="danger large wide" disabled={disabledText !== "archive"}>
            Archive
          </button>
        }
      >
        <p>Archiving a mascot will make it publicly unavailable and inaccessible in your account.</p>
        <p>If you want to unarchive this mascot at any point, please contact us.</p>

        <div className="input-group">
          <label>Enter “archive” to submit</label>
          <input
            onKeyDown={(e) => {
              if (e.keyCode === 32) e.preventDefault();
            }}
            onChange={(e) => setDisabled(e.target.value)}
            ref={archiveInputRef}
            type="text"
            defaultValue=""
            placeholder="archive"
          />
        </div>
      </Modal>
    </>
  );
}
