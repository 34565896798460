import React from "react";
import classNames from "classnames";
import Loader from "../Loader";

export default function SingleValueChart({ value, title, className, label, unit, color, action }) {
  return (
    <div className={classNames("chart", "single-value-chart", className && className)}>
      <h4>{title}</h4>

      {value !== undefined ? (
        <div
          className={classNames(
            'chart-circle',
            action && 'clickable'
          )}
          onClick={action && action}
        >
          <div className="chart-value" style={{ color: color }}>
            {parseInt(value || 0)}
          </div>
          {unit && <div className="meta small">{unit}</div>}
        </div>
      ) : (
        <Loader classNames="dark large"></Loader>
      )}

      <div className="meta chart-label">{label}</div>
    </div>
  );
}
