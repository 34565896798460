import React, { useEffect, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import classNames from "classnames";
import Loader from "../Loader";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function BarChart(props) {
  const [data, setData] = useState();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: function (context) {
          return context.dataset.data.length <= 30 && context.dataset.data[context.dataIndex] > 0;
        },
        clamp: true,
        color: "#000",
        anchor: "end",
        align: "bottom",
        labels: {
          title: {
            font: {
              weight: "bold",
              size: 18,
              family: "Lexend",
            },
          },
        },
      },
    },
    scales: {
      y: {
        display: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          borderColor: props.borderColor || "#334E59",
          borderWidth: 1,
          drawBorder: true,
          display: false,
        },
        ticks: {
          display: true,
          color: "#f7f7f7",
          font: {
            family: "Rubik",
            size: 11,
          },
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
        },
        scaleLabel: {
          display: true,
          color: "#f7f7f7",
        },
      },
    },
    animations: false,
    onClick: (e, clickedElements) => {
      if (props.onClick && clickedElements[0]) props.onClick(props.data[clickedElements[0].index].date);
    },
  };

  useEffect(() => {
    if (props.data) {
      setData({
        labels: props.data.map((d) => d.label),
        datasets: [
          {
            label: "Sessions",
            data: props.data.map((d) => d.value),
            backgroundColor: function (context) {
              if (!context.chart.chartArea) return;
              let gradient = context.chart.ctx.createLinearGradient(
                0,
                context.chart.chartArea.bottom,
                0,
                context.chart.chartArea.top
              );
              gradient.addColorStop(0, "#3DBFBF");
              gradient.addColorStop(1, "#2DE279");
              return gradient;
            },
            borderRadius: 20,
            maxBarThickness: 88,
            minBarLength: 30,
          },
        ],
      });
    }
    // eslint-disable-next-line
  }, [props.data]);

  return (
    <div className={classNames("bar-chart", props.className && props.className)}>
      {props.title && <h4>{props.title}</h4>}
      {props.description && <div className="meta small light">{props.description}</div>}

      {data ? (
        data.datasets && data.datasets[0].data.reduce((acc, curr) => acc + curr, 0) > 0 ? (
          <Bar options={options} data={data} />
        ) : (
          <h5 className="no-data">No data yet</h5>
        )
      ) : (
        <Loader></Loader>
      )}
    </div>
  );
}
